import React from "react";
import PropTypes from "prop-types";
import Link from "arena/components/Link";
import Button from "@mui/material/Button";
import ReactGA from "react-ga4";
import useGuidUrl from "arena/hooks/useGuidUrl";

export default function CoverButton({ button }) {
  const onClickButton = (event, button) => {
    ReactGA.event({
      category: "ArenaCustomCover",
      action: "Click to button",
      label: button.text,
    });
  };

  const resultLink = useGuidUrl(button.link);

  const isInternalLink = resultLink?.startsWith("/");

  const component = isInternalLink ? Link : "a";

  const buttonStyles = {
    backgroundColor: {
      contained: button.backgroundColor,
      outlined: "transparent",
      text: "transparent",
    }[button.variant],
    borderColor: {
      contained: "transparent",
      outlined: button.backgroundColor,
      text: "transparent",
    }[button.variant],
    color: button.textColor,
    "&:hover": {
      backgroundColor: {
        contained: button.backgroundColor,
        outlined: `${button.backgroundColor}15`,
        text: `${button.textColor}1A`, // Полупрозрачный фон текста (10% прозрачности)
      }[button.variant],
      borderColor: {
        contained: "transparent",
        outlined: button.backgroundColor,
        text: "transparent",
      }[button.variant],
      color: button.textColor,
    },
    "&:active": {
      backgroundColor: {
        contained: button.backgroundColor,
        outlined: `${button.backgroundColor}25`,
        text: `${button.textColor}33`, // Более заметный полупрозрачный фон (20% прозрачности)
      }[button.variant],
      borderColor: {
        contained: "transparent",
        outlined: button.backgroundColor,
        text: "transparent",
      }[button.variant],
      color: button.textColor,
    },
    transition: "all 0.2s ease-in-out",
  };

  return (
    <Button
      component={component}
      to={resultLink}
      href={resultLink}
      variant={button.variant}
      target={isInternalLink ? undefined : "_blank"}
      size={button.size}
      onClick={(event) => onClickButton(event, button)}
      sx={buttonStyles}
    >
      {button.text}
    </Button>
  );
}

CoverButton.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
  }).isRequired,
};
