import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useQuery } from "@apollo/client";
import { ProductQuery } from "arena/queries";
import ProductContents from "arena/components/ProductContents";
import Collections from "arena/components/Collections";
import ProductHelmet from "arena/components/ProductHelmet";
import ProductCover from "arena/components/ProductCover";
import ProductHelpDialog from "arena/components/ProductHelpDialog";
import ErrorScreen from "core/components/ErrorScreen";
import { useTranslation } from "react-i18next";
import { useViewer } from "core/components/ViewerProvider";
import NoMatchScreen from "arena/components/NoMatchScreen";
// import useScrollToTop from "core/hooks/useScrollToTop";
import ProductDialog from "arena/components/ProductDialog";
import useProductDialog from "arena/hooks/useProductDialog";
import NoSsr from "@mui/material/NoSsr";
import { useLocation, useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { CloseRouteDialogButton } from "arena/components/RouteDialog";
import ArenaPosts from "arena/components/ArenaPosts";
import ArenaDigitalAssets from "arena/components/ArenaDigitalAssets";
import { styled } from "@mui/material/styles";
import { isTelegramMiniApp } from "core/utils/telegram";
import { useArena } from "core/components/ArenaProvider";

const LATEST = "LATEST";
const STARTED = "STARTED";
const PENDING = "PENDING";
const VIDEO = "VIDEO";
const STREAM = "STREAM";

const Block = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 3),
  },
}));

export default function Product({ params: { productId } }) {
  const { t } = useTranslation("arena");
  const arena = useArena();
  const viewer = useViewer();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [ref, inView] = useInView({
    // rootMargin: "-49% 0px",
    delay: 200,
    triggerOnce: true,
  });

  const { state, dispatch } = useProductDialog();
  const [promocodeIds, setPromocodeIds] = useState([]);
  const [helpOpen, setHelpOpen] = useState(false);

  const onCompleted = (data) => {
    if (data.product) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.product.title,
      });
    }
  };

  const { data, previousData, loading, error, refetch } = useQuery(
    ProductQuery,
    {
      fetchPolicy: "network-only",
      // nextFetchPolicy: "cache-and-network",
      variables: {
        id: productId,
        promocodeIds,
        miniAppWebPayment: arena.isTelegramArena && !isTelegramMiniApp,
      },
      onCompleted,
    }
  );

  const product = data?.product || previousData?.product;

  // useScrollToTop();

  useEffect(() => {
    if (!product) return;
    if (product?.viewerPurchase) return;
    if (!location.hash) return;
    if (!location.hash.startsWith("#price")) return;

    const index = location.hash.replace("#price-", "");
    const price = product.prices[index];

    if (!price) return;

    const { providers } = price;
    const [provider] = providers;

    dispatch({
      type: "create_payment",
      provider,
      providers,
      priceId: price?.id,
      productType: product.type,
    });
  }, [location.hash, product]);

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!product && !loading) {
    return <NoMatchScreen />;
  }

  const onCloseHelpDialog = () => {
    setHelpOpen(false);
    ReactGA.event({
      category: "Product",
      action: "Close help",
      label: product.title,
    });
  };

  const onClickHelpButton = () => {
    setHelpOpen(true);
    ReactGA.event({
      category: "Product",
      action: "Click help button",
      label: product.title,
    });
  };

  const onClickPriceButton = (event, price) => {
    event.preventDefault();

    ReactGA.event({
      category: "Product",
      action: "Click to create payment button",
      label: product.title,
    });

    const { providers } = price;
    const [provider] = providers;

    dispatch({
      type: "create_payment",
      provider,
      providers,
      priceId: price?.id,
      amount: price?.amount,
      recurrent: price?.recurrent,
      productType: product.type,
    });
  };

  const referralContentId = searchParams.get("referralContentId");

  return (
    <Fragment>
      {product && <ProductHelmet product={product} />}

      <ProductCover
        closeRouteDialogButton={<CloseRouteDialogButton />}
        loading={loading}
        onClickHelpButton={onClickHelpButton}
        onClickPriceButton={onClickPriceButton}
        product={product}
        promocodeIds={promocodeIds}
        setPromocodeIds={setPromocodeIds}
        viewer={viewer}
        refetch={refetch}
      />

      <NoSsr>
        {product?.showContent && (
          <div ref={ref} style={{ minHeight: 10 }}>
            {inView && (
              <>
                <Block>
                  <ArenaPosts
                    title={t("product.posts")}
                    view="CAROUSEL"
                    variant="TITLE_OUTSIDE"
                    productIds={[productId]}
                    loadMore
                  />
                </Block>

                <Block>
                  <ArenaDigitalAssets
                    title={t("product.digitalAssets")}
                    view="CAROUSEL"
                    variant="TITLE_OUTSIDE"
                    productIds={[productId]}
                  />
                </Block>

                <ProductContents
                  limit={24}
                  productId={productId}
                  sort={LATEST}
                  status={STARTED}
                  title={t("product.startedStreams")}
                  type={STREAM}
                />
                <ProductContents
                  limit={24}
                  productId={productId}
                  sort={LATEST}
                  status={PENDING}
                  title={t("product.pendingStreams")}
                  type={STREAM}
                />
                <ProductContents
                  limit={24}
                  productId={productId}
                  sort={LATEST}
                  title={t("product.latestVideos")}
                  type={VIDEO}
                />
                <Collections productId={productId} limit={3} sort={LATEST} />
              </>
            )}
          </div>
        )}
      </NoSsr>

      {product && (
        <ProductHelpDialog
          onClose={onCloseHelpDialog}
          open={helpOpen}
          product={product}
        />
      )}

      <ProductDialog
        referralContentId={referralContentId}
        setPromocodeIds={setPromocodeIds}
        product={product}
        state={state}
        dispatch={dispatch}
        refetch={refetch}
      />
    </Fragment>
  );
}

Product.propTypes = {
  params: PropTypes.shape({
    productId: PropTypes.string.isRequired,
  }),
};
