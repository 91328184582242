import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import ButtonBase from "@mui/material/ButtonBase";
import Link from "arena/components/Link";
import { Helmet } from "react-helmet-async";
import { getLogoSrcSet } from "core/utils/getSrcSet";

const MAX_WIDTH = 200;
const MAX_HEIGHT_MOBILE = 30;
const MAX_HEIGHT_DESKTOP = 40;

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  height: MAX_HEIGHT_MOBILE,
  maxWidth: `calc(100vw - 210px)`,
  [theme.breakpoints.up("sm")]: {
    height: MAX_HEIGHT_DESKTOP,
    maxWidth: MAX_WIDTH,
  },
}));

const DEFAULT = "DEFAULT";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

const StyledImage = styled("img")(({ theme }) => ({
  flexShrink: 0,
  flexGrow: 0,
  maxHeight: MAX_HEIGHT_MOBILE,
  maxWidth: `calc(100vw - 210px)`,
  borderRadius: 0,
  display: "block",
  margin: 0,
  border: 0,
  [theme.breakpoints.up("sm")]: {
    maxHeight: MAX_HEIGHT_DESKTOP,
    maxWidth: MAX_WIDTH,
  },
}));

export default function HeaderLogo({ arena }) {
  const onClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {arena.logo && (
        <Helmet>
          <link rel="preload" href={arena.logo.url} as="image" />
        </Helmet>
      )}
      <Tooltip title={arena.title}>
        <StyledButtonBase component={Link} to="/" onClick={onClick}>
          {arena.logo ? (
            <StyledImage
              src={arena.logo.url}
              srcSet={getLogoSrcSet(arena.logo.thumbnails)}
              alt={arena.title}
            />
          ) : (
            arena.title
          )}
        </StyledButtonBase>
      </Tooltip>
    </>
  );
}

HeaderLogo.propTypes = {
  arena: PropTypes.shape({
    title: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      url: PropTypes.string,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
          url: PropTypes.string.isRequired,
          webpUrl: PropTypes.string,
        })
      ),
    }),
  }),
};
