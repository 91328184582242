import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Player from "core/components/Player";
import Skeleton from "@mui/material/Skeleton";
import { trackWidget } from "core/utils/tracker";
import { NextArenaContentQuery } from "arena/queries";
import { useQuery, useSubscription } from "@apollo/client";
import { ContentStreamStatusChangedSubscription } from "core/subscriptions";

const Root = styled("div")({
  overflow: "hidden",
});

const StyledPlayer = styled("div")({
  width: "100%",
  zIndex: 1,
  position: "relative",
  height: 0,
  paddingTop: "56.25%",
  background: "black",
  overflow: "hidden",

  "&:fullscreen": {
    paddingTop: 0,
  },
});

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  zIndex: 1,
  position: "relative",
  height: 0,
  paddingTop: "56.25%",
  background: "black",
  overflow: "hidden",

  [theme.breakpoints.up("sm")]: {
    borderRadius: 4,
  },
}));

const sponsorPlayerLogoPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

const sponsorPlayerButtonPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

const sponsorVideoPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

const sponsorChatMessagePlaces = {
  STREAM: "STREAM_ON_SITE",
};

const sponsorInStreamBannerPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

const sponsorCornerBannerPlaces = {
  VIDEO: "VIDEO_ON_SITE",
  STREAM: "STREAM_ON_SITE",
};

const STREAM = "STREAM";

export default function ArenaTv({
  autoPlay,
  loop,
  muted,
  contentId,
  rightholderIds,
}) {
  const onCompleted = (data) => {
    if (data?.nextArenaContent) {
      if (data.nextArenaContent.widget) {
        trackWidget(data.nextArenaContent.widget, "HIT");
      }
    }
  };

  const variables = {};

  if (contentId) {
    variables.id = contentId;
  }

  if (rightholderIds?.length) {
    variables.rightholderIds = rightholderIds;
  }

  const { data, loading, error, refetch } = useQuery(NextArenaContentQuery, {
    variables,
    skip: !process.browser,
    fetchPolicy: "network-only",
    onCompleted,
  });

  const content = data?.nextArenaContent;

  useSubscription(ContentStreamStatusChangedSubscription, {
    skip: !content || !process.browser || content?.type !== STREAM,
    onData: ({ client, data }) => {
      if (data?.data?.contentStreamStatusChanged?.rejectModeration) {
        client.resetStore();
      }
    },
    variables: {
      id: content?.id,
    },
  });

  if (loading || !process.browser) {
    return <StyledSkeleton variant="rectangular" />;
  }

  if (!content) {
    return null;
  }

  if (error) {
    return null;
  }

  const sponsorPlayerLogoPlace = sponsorPlayerLogoPlaces[content.type];
  const sponsorPlayerButtonPlace = sponsorPlayerButtonPlaces[content.type];
  const sponsorVideoPlace = sponsorVideoPlaces[content.type];
  const sponsorChatMessagePlace = sponsorChatMessagePlaces[content.type];
  const sponsorInStreamBannerPlace = sponsorInStreamBannerPlaces[content.type];
  const sponsorCornerBannerPlace = sponsorCornerBannerPlaces[content.type];

  const onEnded = () => {
    refetch();
  };

  return (
    <Root>
      <StyledPlayer id="arena-player">
        <Player
          loop={loop}
          muted={muted}
          autoPlay={autoPlay}
          content={content}
          key={content.id}
          hideRelatedContent
          hideShare
          hideLogo
          updateVideoPosition
          onEnded={onEnded}
          fullscreenTarget="arena-player"
          sponsorCornerBannerPlace={sponsorCornerBannerPlace}
          sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
          sponsorPlayerLogoPlace={sponsorPlayerLogoPlace}
          sponsorPlayerButtonPlace={sponsorPlayerButtonPlace}
          sponsorVideoPlace={sponsorVideoPlace}
          sponsorChatMessagePlace={sponsorChatMessagePlace}
        />
      </StyledPlayer>
    </Root>
  );
}

ArenaTv.propTypes = {
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  contentId: PropTypes.string,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
};
