import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import clip from "text-clipper";

export default function ProductDescription({
  className,
  viewerPurchase,
  product,
}) {
  const [collapsed, setCollapsed] = useState(true);

  if (!viewerPurchase) {
    return (
      <CardContent>
        <Typography
          component="div"
          variant="body1"
          color="textPrimary"
          className={className}
          dangerouslySetInnerHTML={{
            __html: product.html,
          }}
          sx={{
            "& :first-of-type": {
              marginTop: 0,
            },
            "& :last-of-type": {
              marginBottom: 8,
            },
          }}
        />
      </CardContent>
    );
  }

  const html = collapsed
    ? clip(product.html, 250, { html: true, maxLines: 5 })
    : product.html;

  return (
    <>
      <CardContent>
        <Typography
          onClick={() => setCollapsed(!collapsed)}
          component="div"
          variant="body1"
          color="textPrimary"
          className={className}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
          sx={{
            "& :first-of-type": {
              marginTop: 0,
            },
            "& :last-of-type": {
              marginBottom: 8,
            },
          }}
        />
      </CardContent>
      {product.html.length > html.length && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: 0,
          }}
        >
          <Button
            fullWidth
            color="inherit"
            onClick={() => setCollapsed(!collapsed)}
            sx={{ borderRadius: 0 }}
          >
            {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Button>
        </Box>
      )}
    </>
  );
}

ProductDescription.propTypes = {
  className: PropTypes.string,
  product: PropTypes.shape({
    description: PropTypes.string,
    html: PropTypes.string,
  }),
  viewerPurchase: PropTypes.object,
};
