import React from "react";
import PropTypes from "prop-types";
import { useLocation, Link as RouterLink } from "react-router-dom";

/**
 * Мы сделали кастомный компонент Link для того чтобы правильно обрабатывать
 * ссылки на страницу видосика или трансляции (которые открываются в попапе)
 */

const Link = React.forwardRef(function Link(props, ref) {
  const location = useLocation();

  const state = {};
  const to = props.to;

  if (isDialogLink(to)) {
    state.background = location?.state?.background || location;
  }

  return <RouterLink {...props} to={to} state={state} ref={ref} />;
});

Link.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Link;

function isDialogLink(to) {
  return (
    to.startsWith("/video/") ||
    to.startsWith("/stream/") ||
    to.startsWith("/product/") ||
    to.startsWith("/digitalAsset/") ||
    to.startsWith("/post/")
  );
}
