import React from "react";
import PropTypes from "prop-types";
import ErrorScreen from "core/components/ErrorScreen";
import PlayerPreview from "core/components/PlayerPreview";
import { useTranslation } from "react-i18next";
import ScheduleIcon from "@mui/icons-material/Schedule";

export default function WaitingScreen({ content, closeRouteDialogButton }) {
  const { t } = useTranslation("core");

  return (
    <PlayerPreview preview={content.preview} overlay>
      <ErrorScreen
        icon={ScheduleIcon}
        text={t("waitingScreen.text", { date: content.viewerPurchase.startAt })}
      />
      {closeRouteDialogButton}
    </PlayerPreview>
  );
}

WaitingScreen.propTypes = {
  closeRouteDialogButton: PropTypes.bool,
  content: PropTypes.shape({
    title: PropTypes.string,
    preview: PropTypes.string,
    premium: PropTypes.bool,
    viewerPurchase: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      startAt: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
