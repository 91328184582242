import React from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";
import { useTranslation } from "react-i18next";

export default function CommentMenuDrawer({
  selectedComment,
  onClose,
  onClickEdit,
  onClickDelete,
  onClickDeleteAllUserComments,
  onClickBanUserPermanent,
  onClickBanUserTemporary,
  viewer,
}) {
  const { t } = useTranslation("arena");
  const isCreator = viewer?.id === selectedComment?.creator.id;
  const buttons = [
    // {
    //   icon: <FlagIcon />,
    //   text: "Report",
    //   visible: !!viewer && viewer?.id !== selectedComment?.creator.id,
    // },
    {
      icon: <EditIcon />,
      text: t("commentMenuDrawer.edit"),
      onClick: onClickEdit,
      visible: isCreator,
    },
    {
      icon: <DeleteIcon />,
      text: t("commentMenuDrawer.delete"), //"Delete ",
      onClick: onClickDelete,
      visible: isCreator || viewer?.isArenaAdmin,
    },
    {
      icon: <DeleteIcon />,
      text: t("commentMenuDrawer.deleteAllUserComments"), //"Delete all user comments",
      onClick: onClickDeleteAllUserComments,
      visible: !isCreator && viewer?.isArenaAdmin,
    },
    {
      icon: <BlockIcon />,
      text: t("commentMenuDrawer.banUserPermanent"), //"",
      onClick: onClickBanUserPermanent,
      visible: !isCreator && viewer?.isArenaAdmin,
    },
    {
      icon: <BlockIcon />,
      text: t("commentMenuDrawer.banUserTemporary"), //"",
      onClick: onClickBanUserTemporary,
      visible: !isCreator && viewer?.isArenaAdmin,
    },
  ].filter((button) => button.visible);

  const open = Boolean(selectedComment);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      sx={{
        zIndex: "1399",
        position: "absolute",
        "&.MuiDrawer-root": {
          display: open ? "flex" : "none",
          position: "absolute",
        },
        "& .MuiDrawer-paper": {
          height: "auto",
          position: "absolute",
          // position: "absolute",
        },
      }}
      disablePortal // это нужно чтобы в миниапе не прыгало
      disableAutoFocus // это нужно чтобы в миниапе не прыгало
      disableRestoreFocus
      keepMounted // это нужно чтобы в миниапе не прыгало
      elevation={0}
    >
      <List>
        {buttons.map((button) => (
          <ListItem button key={button.text} onClick={button.onClick}>
            <ListItemIcon>{button.icon}</ListItemIcon>
            <ListItemText primary={button.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

CommentMenuDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickDeleteAllUserComments: PropTypes.func.isRequired,
  onClickBanUserPermanent: PropTypes.func.isRequired,
  onClickBanUserTemporary: PropTypes.func.isRequired,
  selectedComment: PropTypes.shape({
    creator: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isArenaAdmin: PropTypes.bool,
  }),
};
