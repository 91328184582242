import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import Tooltip from "@mui/material/Tooltip";
import { numberFormat } from "core/utils/string";
import { useMutation } from "@apollo/client";
import { CreateLikeMutation, DeleteLikeMutation } from "arena/mutations";
// import { ItemLikesQuery } from "arena/queries";
import { useViewer } from "core/components/ViewerProvider";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Likes({ contentId, commentId, likes, borderRadius }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const { t } = useTranslation("arena");
  const viewer = useViewer();

  const [createLike] = useMutation(CreateLikeMutation);

  const [deleteLike] = useMutation(DeleteLikeMutation, {
    variables: { input: { contentId, commentId } },
  });

  const deleteLikes = () => {
    deleteLike();
  };

  const onClickLike = () => {
    if (!viewer) return document.querySelector(".js-sign-in").click();

    if (likes?.liked) {
      deleteLikes();
      return;
    }

    createLike({
      variables: {
        input: {
          contentId,
          commentId,
          type: "LIKE",
        },
      },
    });
  };

  const onClickDislike = () => {
    if (!viewer) return document.querySelector(".js-sign-in").click();

    if (likes?.disliked) {
      deleteLikes();
      return;
    }

    createLike({
      variables: {
        input: {
          contentId,
          commentId,
          type: "DISLIKE",
        },
      },
    });
  };

  const count = numberFormat(likes?.count || 0);

  return (
    <Stack direction="row" spacing={0}>
      <Tooltip title={likes?.liked ? t("likes.unlike") : t("likes.like")}>
        <Button
          variant="text"
          color="inherit"
          startIcon={
            <ThumbUpIcon color={likes?.liked ? "primary" : "inherit"} />
          }
          size={sm ? "large" : "medium"}
          onClick={onClickLike}
          sx={{
            borderRadius,
          }}
        >
          {count}
        </Button>
      </Tooltip>
      <Tooltip
        title={likes?.disliked ? t("likes.undislike") : t("likes.dislike")}
      >
        <Button
          variant="text"
          color="inherit"
          size={sm ? "large" : "medium"}
          onClick={onClickDislike}
          sx={{
            minWidth: 40,
            borderRadius,
          }}
        >
          <ThumbDownIcon
            sx={{ fontSize: 18 }}
            color={likes?.disliked ? "primary" : "inherit"}
          />
        </Button>
      </Tooltip>
    </Stack>
  );
}

Likes.propTypes = {
  likes: PropTypes.shape({
    liked: PropTypes.bool,
    disliked: PropTypes.bool,
    count: PropTypes.number,
    id: PropTypes.string,
  }),
  contentId: PropTypes.string,
  commentId: PropTypes.string,
  borderRadius: PropTypes.number,
};
