import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SubscribeStreamButton from "core/components/SubscribeStreamButton";
import ContentPlayer from "arena/components/ContentPlayer";
import ContentBody from "arena/components/ContentBody";
import ErrorScreen from "core/components/ErrorScreen";
import ContentComments from "arena/components/ContentComments";
import useWsLinkStatus from "core/hooks/useWsLinkStatus";
import { CloseRouteDialogButton } from "arena/components/RouteDialog";
import { ContentQuery, ContentStreamStatusQuery } from "arena/queries";
import { useApolloClient, useSubscription, useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import { createEvent } from "core/utils/tracker";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import NoMatchScreen from "arena/components/NoMatchScreen";
// import { useViewer } from "core/components/ViewerProvider";
import ContentHelmet from "arena/components/ContentHelmet";
// import SponsorChatTopBanner from "core/components/SponsorChatTopBanner";
import addAdToChat from "core/utils/addAdToChat";
import {
  ContentStreamStatusChangedSubscription,
  ContentViewersOnlineChangedSubscription,
} from "core/subscriptions";
import { useArena } from "core/components/ArenaProvider";
import Box from "@mui/material/Box";
import ContentLayout from "arena/components/ContentLayout";
import RelatedContent from "arena/components/RelatedContent";
import { useTranslation } from "react-i18next";
import useIsMobile from "arena/hooks/useIsMobile";

const STATUS_POLL_INTERVAL = 10 * 1000; // 10s

// const SITE = "SITE";
const STREAM = "STREAM";
const STREAM_ON_SITE = "STREAM_ON_SITE";

export default function Stream({ params: { contentId } }) {
  const { t } = useTranslation("arena");
  const isMobile = useIsMobile();
  const arena = useArena();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const client = useApolloClient();

  // const params = useParams();
  // const { contentId } = params;
  const productId = searchParams.get("productId");
  const startOffset = searchParams.get("startOffset")
    ? Number(searchParams.get("startOffset"))
    : 0;

  const { online: wsOnline } = useWsLinkStatus();

  // const viewer = useViewer();

  const onCompleted = (data) => {
    if (data.content) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.content.title,
      });

      createEvent("page", "load");
    }
  };

  const { data, loading, error, refetch } = useQuery(ContentQuery, {
    variables: {
      contentId,
    },
    onCompleted,
  });

  useSubscription(ContentStreamStatusChangedSubscription, {
    skip: !process.browser,
    onData: ({ client, data }) => {
      if (data?.data?.contentStreamStatusChanged?.rejectModeration) {
        client.resetStore();
      }
    },
    variables: {
      id: contentId,
    },
  });

  useSubscription(ContentViewersOnlineChangedSubscription, {
    skip: !process.browser,
    variables: {
      id: contentId,
    },
  });

  /**
   * При отключении websocket
   * что бы не пропустить начало или конец стрима
   * запускаем polling
   */
  useQuery(ContentStreamStatusQuery, {
    skip: wsOnline || !data,
    pollInterval: wsOnline ? 0 : STATUS_POLL_INTERVAL,
    variables: {
      contentId,
    },
  });

  const content = data?.content;

  const rejectModeration = content?.rejectModeration;

  useEffect(() => {
    if (rejectModeration) {
      client.resetStore();
    }
  }, [rejectModeration]);

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!content && !loading) {
    return <NoMatchScreen />;
  }

  if (content && content.type !== STREAM) {
    return (
      <Navigate
        to={content.siteUrl.replace("stream", content.type.toLowerCase())}
      />
    );
  }

  const onChatAdv = (sponsorChatMessage) => {
    addAdToChat(content, client, sponsorChatMessage);
  };

  const tabsSchema = {
    RELATED_VIDEO: {
      label: t("video.tabsSchema.relatedVideo"),
      component: (
        <RelatedContent
          type="VIDEO"
          limit={6}
          selectedContentId={contentId}
          relatedContentId={contentId}
          productId={productId}
        />
      ),
      enabled: true,
    },
    COMMENTS: {
      label: t("video.tabsSchema.comments"),
      component: <ContentComments contentId={contentId} />,
      enabled: content?.isCommentsEnabled,
    },
  };

  return (
    <Box data-testid="Stream" key={contentId}>
      <ContentHelmet content={content} arena={arena} />

      <ContentLayout tabsSchema={tabsSchema}>
        <ContentPlayer
          sponsorCornerBannerPlace={STREAM_ON_SITE}
          sponsorInStreamBannerPlace={STREAM_ON_SITE}
          sponsorPlayerLogoPlace={STREAM_ON_SITE}
          sponsorPlayerButtonPlace={STREAM_ON_SITE}
          sponsorVideoPlace={STREAM_ON_SITE}
          sponsorChatMessagePlace={STREAM_ON_SITE}
          hideTitle
          autoPlay
          content={content}
          loading={loading}
          subscribeButton={
            content && <SubscribeStreamButton content={content} />
          }
          onChatAdv={onChatAdv}
          refetch={refetch}
          startOffset={startOffset}
          // chatEnabled={chatEnabled}
          closeRouteDialogButton={<CloseRouteDialogButton />}
        />
        <ContentBody content={content} loading={loading} />

        {content?.isCommentsEnabled && isMobile && (
          <ContentComments contentId={contentId} isMobile />
        )}
      </ContentLayout>

      {isMobile && (
        <RelatedContent
          type="VIDEO"
          limit={6}
          selectedContentId={contentId}
          relatedContentId={contentId}
          productId={productId}
          isMobile
        />
      )}

      {/* {chatEnabled && (
            <div className={classes.chat}>
              <div className={classes.chatInner}>
                {content?.isAdvEnabled && (
                  <SponsorChatTopBanner
                    contentId={contentId}
                    rightholderId={content.rightholder.id}
                    place={SITE}
                  />
                )}

                <Chat content={content} loading={loading} viewer={viewer} />
              </div>
            </div>
          )} */}
    </Box>
  );
}

Stream.propTypes = {
  params: PropTypes.shape({
    contentId: PropTypes.string.isRequired,
  }),
};
