import ReactGA from "react-ga4";

let initialized = false;

export default function initializeGa(arena) {
  if (!arena) return;
  if (!arena.gaId) return;
  if (initialized) return;
  if (!process.browser) return;

  ReactGA.initialize(arena.gaId);

  initialized = true;
}
