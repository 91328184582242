import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useTranslation } from "react-i18next";
import { shareURL } from "@telegram-apps/sdk";
import { isTelegramMiniApp } from "core/utils/telegram";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ShareButton({ shareData }) {
  const { t } = useTranslation("arena");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  if (!process.browser) return null;
  if (process.env.NODE_ENV === "production") {
    if (isTelegramMiniApp) {
      if (!shareURL.isAvailable()) {
        return null;
      }
    } else {
      if (!("share" in navigator)) return null;
    }
  }

  const onClick = () => {
    if (isTelegramMiniApp) {
      return shareURL(shareData.url, shareData.title);
    }

    navigator.share(shareData);
  };

  return (
    <Button
      variant="text"
      color="inherit"
      startIcon={<IosShareIcon />}
      size={sm ? "large" : "medium"}
      onClick={onClick}
      sx={{ borderRadius: 0, pr: 1.5 }}
    >
      {t("share")}
    </Button>
  );
}

ShareButton.propTypes = {
  shareData: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  }),
};
