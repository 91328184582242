import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import PromoButton from "core/components/PromoButton";
import FormWidget from "core/components/FormWidget";
import { trackWidget } from "core/utils/tracker";

const LINK = "LINK";
const FORM = "FORM";

export default function PlayerHeader({
  content,
  visible,
  tracker,
  preview,
  closeRouteDialogButton,
}) {
  const [isFormOpen, setFormOpen] = useState(false);

  const onClickLinkButton = () => {
    if (tracker) {
      trackWidget(content.widget, "CLICK");
    }
  };

  const onClickFormButton = () => {
    if (tracker) {
      trackWidget(content.widget, "CLICK");
    }
    setFormOpen(true);
  };

  const isLink = content?.widget?.type === LINK;
  const isForm = content?.widget?.type === FORM;

  // Хардкод для париматч
  const isParimatch = content.rightholder.slug === "abff";

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const hitWidget = content?.widget?.id && inView;

  useEffect(() => {
    if (tracker) {
      if (hitWidget) {
        trackWidget(content.widget, "HIT");
      }
    }
  }, [hitWidget, tracker]);

  return (
    <>
      {isLink && (
        <div ref={ref}>
          <PromoButton
            onClick={onClickLinkButton}
            url={content.widget.params.buttonUrl}
            text={content.widget.params.buttonText}
            color={content.widget.params.buttonColor}
            textColor={content.widget.params.buttonTextColor}
            visible={content.widget.pin ? true : visible}
            isParimatch={isParimatch}
          />
        </div>
      )}
      {isForm && (
        <div ref={ref}>
          <PromoButton
            onClick={onClickFormButton}
            text={content.widget.params.buttonText}
            color={content.widget.params.buttonColor}
            textColor={content.widget.params.buttonTextColor}
            visible={content.widget.pin ? true : visible}
            isParimatch={isParimatch}
          />
        </div>
      )}
      {isForm && (
        <FormWidget
          open={isFormOpen}
          onClose={() => setFormOpen(false)}
          termsOfServiceUrl={content.widget.termsOfServiceUrl}
          privacyPolicyUrl={content.widget.privacyPolicyUrl}
          color={content.widget.params.buttonColor}
          textColor={content.widget.params.buttonTextColor}
          title={content.widget.params.formTitle}
          subtitle={content.widget.params.formSubtitle}
          inputLabel={content.widget.params.formInputLabel}
          submitText={content.widget.params.formSubmitText}
          inputType={content.widget.params.formInputType}
          widgetId={content.widget.id}
          preview={preview}
        />
      )}
      {visible && closeRouteDialogButton}
    </>
  );
}

PlayerHeader.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    logo: PropTypes.string,
    widget: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["FORM", "LINK"]),
      pin: PropTypes.bool.isRequired,
      termsOfServiceUrl: PropTypes.string.isRequired,
      privacyPolicyUrl: PropTypes.string.isRequired,
      params: PropTypes.shape({
        buttonUrl: PropTypes.string,
        buttonText: PropTypes.string,
        buttonColor: PropTypes.string,
        buttonTextColor: PropTypes.string,
        formTitle: PropTypes.string,
        formSubtitle: PropTypes.string,
        formInputLabel: PropTypes.string,
        formSubmitText: PropTypes.string,
        formInputType: PropTypes.string,
      }),
    }),
    rightholder: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      hideTitle: PropTypes.bool,
    }),
  }),
  tracker: PropTypes.bool,
  visible: PropTypes.bool,
  preview: PropTypes.bool,
  position: PropTypes.string,
  closeRouteDialogButton: PropTypes.node,
};
