import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Link from "arena/components/Link";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import PoweredBy from "arena/components/PoweredBy";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import SwitchLanguage from "arena/components/SwitchLanguage";

const PREFIX = "Footer";

const classes = {
  icon: `${PREFIX}-icon`,
  toolbar: `${PREFIX}-toolbar`,
  appBar: `${PREFIX}-appBar`,
  root: `${PREFIX}-root`,
  poweredBy: `${PREFIX}-poweredBy`,
  poweredByLink: `${PREFIX}-poweredByLink`,
  poweredByImage: `${PREFIX}-poweredByImage`,
};

const Root = styled("footer")(({ theme }) => ({
  [`& .${classes.icon}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },

  [`& .${classes.appBar}`]: {
    // backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.toolbar}`]: {
    // backgroundColor: "rgba(255, 255, 255, 0.03)",
    paddingTop: 16,
    paddingBottom: 16,
    pointerEvents: "all",
  },

  [`&.${classes.root}`]: {
    pointerEvents: "none",
    position: "relative",
    zIndex: 2,
    marginTop: "auto",
    paddingTop: theme.spacing(10),
    width: "100%",
  },
}));

const ICON_PROPS = {
  TWITTER: {
    viewBox: "0 0 32 32",
  },
};

const ICONS = {
  FACEBOOK: (
    <path d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
  ),
  TWITTER: (
    <path d="M 4.0175781 4 L 13.091797 17.609375 L 4.3359375 28 L 6.9511719 28 L 14.246094 19.34375 L 20.017578 28 L 20.552734 28 L 28.015625 28 L 18.712891 14.042969 L 27.175781 4 L 24.560547 4 L 17.558594 12.310547 L 12.017578 4 L 4.0175781 4 z M 7.7558594 6 L 10.947266 6 L 24.279297 26 L 21.087891 26 L 7.7558594 6 z"></path>
  ),
  YOUTUBE: (
    <path d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" />
  ),
  INSTAGRAM: (
    <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
  ),
  TELEGRAM: (
    <path d="M9.78,18.65L10.06,14.42L17.74,7.5C18.08,7.19 17.67,7.04 17.22,7.31L7.74,13.3L3.64,12C2.76,11.75 2.75,11.14 3.84,10.7L19.81,4.54C20.54,4.21 21.24,4.72 20.96,5.84L18.24,18.65C18.05,19.56 17.5,19.78 16.74,19.36L12.6,16.3L10.61,18.23C10.38,18.46 10.19,18.65 9.78,18.65Z" />
  ),
  ODNOKLASSNIKI: (
    <path d="M17.83,12.74C17.55,12.17 16.76,11.69 15.71,12.5C14.28,13.64 12,13.64 12,13.64C12,13.64 9.72,13.64 8.29,12.5C7.24,11.69 6.45,12.17 6.17,12.74C5.67,13.74 6.23,14.23 7.5,15.04C8.59,15.74 10.08,16 11.04,16.1L10.24,16.9C9.1,18.03 8,19.12 7.25,19.88C6.8,20.34 6.8,21.07 7.25,21.5L7.39,21.66C7.84,22.11 8.58,22.11 9.03,21.66L12,18.68C13.15,19.81 14.24,20.9 15,21.66C15.45,22.11 16.18,22.11 16.64,21.66L16.77,21.5C17.23,21.07 17.23,20.34 16.77,19.88L13.79,16.9L13,16.09C13.95,16 15.42,15.73 16.5,15.04C17.77,14.23 18.33,13.74 17.83,12.74M12,4.57C13.38,4.57 14.5,5.69 14.5,7.06C14.5,8.44 13.38,9.55 12,9.55C10.62,9.55 9.5,8.44 9.5,7.06C9.5,5.69 10.62,4.57 12,4.57M12,12.12C14.8,12.12 17.06,9.86 17.06,7.06C17.06,4.27 14.8,2 12,2C9.2,2 6.94,4.27 6.94,7.06C6.94,9.86 9.2,12.12 12,12.12Z" />
  ),
  VKONTAKTE: (
    <path d="M20.8,7.74C20.93,7.32 20.8,7 20.18,7H18.16C17.64,7 17.41,7.27 17.28,7.57C17.28,7.57 16.25,10.08 14.79,11.72C14.31,12.19 14.1,12.34 13.84,12.34C13.71,12.34 13.5,12.19 13.5,11.76V7.74C13.5,7.23 13.38,7 12.95,7H9.76C9.44,7 9.25,7.24 9.25,7.47C9.25,7.95 10,8.07 10.05,9.44V12.42C10.05,13.08 9.93,13.2 9.68,13.2C9,13.2 7.32,10.67 6.33,7.79C6.13,7.23 5.94,7 5.42,7H3.39C2.82,7 2.7,7.27 2.7,7.57C2.7,8.11 3.39,10.77 5.9,14.29C7.57,16.7 9.93,18 12.08,18C13.37,18 13.53,17.71 13.53,17.21V15.39C13.53,14.82 13.65,14.7 14.06,14.7C14.36,14.7 14.87,14.85 16.07,16C17.45,17.38 17.67,18 18.45,18H20.47C21.05,18 21.34,17.71 21.18,17.14C21,16.57 20.34,15.74 19.47,14.76C19,14.21 18.29,13.61 18.07,13.3C17.77,12.92 17.86,12.75 18.07,12.4C18.07,12.4 20.54,8.93 20.8,7.74Z" />
  ),
  TIKTOK: (
    <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"></path>
  ),
};

const date = new Date().getFullYear();

export default function Footer({ arena }) {
  const { t } = useTranslation("arena");
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  const title = arena.footer.title?.replace("{date}", date);
  const subtitle = arena.footer.subtitle?.replace("{date}", date);

  const {
    privacyPolicyUrl,
    termsOfServiceUrl,
    refundPolicyUrl,
    showPoweredBy,
    showTerms,
    supportEmail,
    showSupportEmail,
  } = arena;

  let terms = [];

  if (showTerms) {
    if (privacyPolicyUrl) {
      terms.push({ url: privacyPolicyUrl, label: t("footer.privacyPolicy") });
    }

    if (termsOfServiceUrl) {
      terms.push({ url: termsOfServiceUrl, label: t("footer.termsOfService") });
    }

    if (refundPolicyUrl) {
      terms.push({ url: refundPolicyUrl, label: t("footer.refundPolicy") });
    }
  }

  return (
    <Root className={classes.root}>
      <AppBar
        className={classes.appBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar className={classes.toolbar}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} lg={3}>
              {title && (
                <Typography
                  color="textPrimary"
                  variant="body2"
                  gutterBottom
                  align={lg ? "left" : "center"}
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              )}
              {subtitle && (
                <Typography
                  color="textSecondary"
                  variant="caption"
                  align={lg ? "left" : "center"}
                  display="block"
                  dangerouslySetInnerHTML={{
                    __html: subtitle,
                  }}
                />
              )}
              {showSupportEmail && supportEmail && (
                <Typography
                  color="textSecondary"
                  variant="caption"
                  align={lg ? "left" : "center"}
                  display="block"
                >
                  <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} lg={6}>
              {terms.length > 0 && (
                <Stack direction="row" spacing={2} justifyContent="center">
                  {terms.map((term) => (
                    <MuiLink
                      component={term.url.startsWith("/") ? Link : "a"}
                      to={term.url}
                      href={term.url}
                      color="textSecondary"
                      underline="hover"
                      variant="caption"
                      key={term.label}
                      className={term.className}
                    >
                      {term.label}
                    </MuiLink>
                  ))}
                </Stack>
              )}
            </Grid>

            <Grid item xs={12} lg={3}>
              <Grid
                container
                spacing={2}
                justifyContent={lg ? "flex-end" : "center"}
              >
                {arena.footer.social.map((social, index) => (
                  <Grid item key={index}>
                    <IconButton
                      href={social.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.icon}
                      size="small"
                      aria-label={social.type}
                    >
                      <SvgIcon color="inherit" {...ICON_PROPS[social.type]}>
                        {ICONS[social.type]}
                      </SvgIcon>
                    </IconButton>
                  </Grid>
                ))}
                {arena.multilingual && (
                  <Grid
                    item
                    xs={12}
                    sm="auto"
                    container
                    justifyContent="center"
                  >
                    <SwitchLanguage />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {showPoweredBy && (
              <Grid item xs={12} lg={3}>
                <PoweredBy arena={arena} />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Root>
  );
}

Footer.propTypes = {
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    viewerCountry: PropTypes.string,
    refundPolicyUrl: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    termsOfServiceUrl: PropTypes.string,
    showPoweredBy: PropTypes.bool,
    showTerms: PropTypes.bool,
    multilingual: PropTypes.bool,
    supportEmail: PropTypes.string,
    showSupportEmail: PropTypes.bool,
    footer: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      social: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    }),
  }),
};
