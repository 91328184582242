import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { withSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import VerifyEmailForm from "core/components/VerifyEmailForm";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useMutation } from "@apollo/client";
import { SignInMutation, SkipEmailErrorMutation } from "core/mutations";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";

function EmailErrorDialog({ viewer, enqueueSnackbar }) {
  const client = useApolloClient();
  const theme = useTheme();
  const { t } = useTranslation("arena");
  const [token, setToken] = useState();
  const { suggestionEmail } = viewer.emailError;
  const [email, setEmail] = useState(suggestionEmail);
  const [open, setOpen] = useState(true);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const [signIn, { loading }] = useMutation(SignInMutation, {
    variables: {
      email,
      transfer: true,
    },
    onCompleted: (data) => {
      setToken(data.signIn.token);
      enqueueSnackbar(t("emailErrorDialog.codeSent"), {
        variant: "success",
      });
    },
    onError,
  });

  const [skip] = useMutation(SkipEmailErrorMutation, {
    refetchQueries: ["Viewer"],
  });

  const onChange = (event) => setEmail(event.target.value.trim());

  const onSkip = () => {
    skip();
    onCloseDialog();
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  const onVerifyEmailSuccess = () => {
    enqueueSnackbar(t("emailErrorDialog.emailVerified"), {
      variant: "success",
    });
    client.resetStore();
  };

  const onSubmitEmail = (event) => {
    event.preventDefault();
    signIn();
  };

  const isValid = !!email && email.includes("@") && email.includes(".");

  return (
    <Dialog
      fullWidth
      // disableScrollLock
      disablePortal
      fullScreen={fullScreen}
      maxWidth="sm"
      open={open}
      onClose={onCloseDialog}
      scroll="body"
    >
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h5" sx={{ flex: 1 }}>
            {token
              ? t("emailErrorDialog.confirmTitle")
              : t("emailErrorDialog.title")}
          </Typography>
          {token ? (
            <IconButton onClick={onCloseDialog} size="large">
              <CloseIcon />
            </IconButton>
          ) : (
            <Button
              disableElevation
              variant="outlined"
              color="inherit"
              type="submit"
              disabled={loading}
              onClick={onSkip}
            >
              {t("skip")}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Stack spacing={2}>
          {token ? (
            <Box>{t("emailErrorDialog.confirmNewEmail")}</Box>
          ) : (
            <>
              <Box
                dangerouslySetInnerHTML={{
                  __html: t("emailErrorDialog.description", {
                    email: viewer.email,
                  }),
                }}
              />
              <Box>{t("emailErrorDialog.checkEmail")}</Box>
              {suggestionEmail && (
                <Box
                  dangerouslySetInnerHTML={{
                    __html: t("emailErrorDialog.enterNewEmailWithSuggestion", {
                      email: suggestionEmail,
                    }),
                  }}
                />
              )}
            </>
          )}

          {token ? (
            <Box>
              <VerifyEmailForm
                onCompleted={onVerifyEmailSuccess}
                onError={onError}
                token={token}
                email={email}
              />
            </Box>
          ) : (
            <Stack spacing={2} component="form" onSubmit={onSubmitEmail}>
              <TextField
                autoFocus
                required
                fullWidth
                variant="outlined"
                label="Email"
                disabled={loading}
                helperText={t("emailErrorDialog.emailText")}
                name="email"
                value={email}
                onChange={onChange}
              />

              <Button
                disableElevation
                component="button"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                fullWidth
                disabled={loading || !isValid}
              >
                {t("emailErrorDialog.verify")}
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

EmailErrorDialog.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  arena: PropTypes.object,
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string,
    emailError: PropTypes.shape({
      id: PropTypes.string.isRequired,
      suggestionEmail: PropTypes.string,
    }),
  }),
};

export default withSnackbar(EmailErrorDialog);
