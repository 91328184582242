import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import ContentDesktopSidebar from "arena/components/ContentDesktopSidebar";

export default function ContentLayout({ children, tabsSchema }) {
  const tabs = Object.keys(tabsSchema)
    .filter((key) => tabsSchema[key].enabled)
    .map((key) => ({
      label: tabsSchema[key].label,
      value: key,
    }));

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "100%" }}>{children}</Box>

      {tabs.length > 0 && (
        <ContentDesktopSidebar tabsSchema={tabsSchema} tabs={tabs} />
      )}
    </Box>
  );
}

ContentLayout.propTypes = {
  children: PropTypes.node,
  tabsSchema: PropTypes.object.isRequired,
};
