import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import getMinPrice from "core/utils/getMinPrice";
import formatCurrency from "core/utils/currency";
import ChooseProductDialog from "core/components/ChooseProductDialog";
import { useTheme, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import StarsIcon from "core/components/StarsIcon";

const SUBSCRIPTION = "SUBSCRIPTION";
const PPV = "PPV";
const DONATION = "DONATION";

/**
 * Получить текст для кнопки
 * @param {Function} t
 * @param {Object} button
 * @returns
 */
function getPriceButtonText(t, button, isTelegramArena) {
  const { product, type, minPrice } = button;

  const prefix = isTelegramArena ? "paymentScreen.telegram" : "paymentScreen";
  const value =
    minPrice.currency === "XTR"
      ? minPrice.value
      : formatCurrency(minPrice.value, minPrice.currency);

  if (type === DONATION) {
    return t("paymentScreen.donate");
  }

  if (type === SUBSCRIPTION) {
    if (minPrice.value === 0) {
      return t(`${prefix}.recurrentPricesFree`);
    }

    if (product.prices.length > 1) {
      return t(`${prefix}.recurrentPrices`, { value });
    }

    return t(`${prefix}.recurrentPrice`, { value });
  }

  if (type === PPV) {
    if (product.prices.length > 1) {
      return t(`${prefix}.ppvPrices`, { value });
    }

    return t(`${prefix}.ppvPrice`, { value });
  }

  throw new Error("unknown button type"); // до сюда по идее никогда не дойдет, но на всякий случай сделаю эксепшн
}

function getButton(buttons, type) {
  const items = buttons.filter((b) => b.type === type);
  const isMany = items.length > 1;
  const item = items.pop();

  if (item) {
    return {
      type,
      isMany,
      product: item.product,
      minPrice: item.minPrice,
    };
  }
}

/**
 * Получить данные для отрисовки кнопок
 * @param {Array<Product>} products
 * @returns
 */
function getButtons(products = []) {
  const buttons = products
    .map((product) => {
      const minPrice = getMinPrice(product);
      const type = product.type;
      return {
        type,
        product,
        minPrice,
      };
    })
    .sort((a, b) => b.minPrice.value - a.minPrice.value);

  return [
    getButton(buttons, SUBSCRIPTION),
    getButton(buttons, PPV),
    getButton(buttons, DONATION),
  ].filter(Boolean);
}

const prefix = "PaymentButtonsButton";

const classes = {
  star: `${prefix}-starsIcon`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  gap: "5px",
  alignItems: "baseline",
  [`& .${classes.star}`]: {
    // marginLeft: "4px",
    // marginTop: "-3px",
    // mt: "0px",
    fontSize: "12px",
  },

  [theme.breakpoints.down("sm")]: {},

  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.4rem",

    [`& .${classes.star}`]: {
      // marginLeft: "4px",
      // marginTop: "-3px",
      fontSize: "18px",
    },
  },
}));

export default function PaymentButtons({
  products,
  onClickProductButton,
  referralContentId,
  isTelegramArena,
}) {
  const { t } = useTranslation("core");

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const buttons = getButtons(products);

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const productsToDialog = products.filter((product) => {
    return product.type === open;
  });

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        {buttons.map((button) => {
          const { isMany, product, type } = button;

          const text = getPriceButtonText(t, button, isTelegramArena);

          const onClick = (event) => {
            if (onClickProductButton) {
              event.preventDefault();
              event.stopPropagation();

              if (isMany) {
                switch (type) {
                  case SUBSCRIPTION:
                    return onClickProductButton({
                      type: "choose_subscriptions",
                    });
                  case PPV:
                    return onClickProductButton({ type: "choose_ppv" });
                  case DONATION:
                    return onClickProductButton({ type: "choose_donates" });
                  default:
                    return; // это никогда не будет достигнуто
                }
              }

              return onClickProductButton({
                type: "product",
                productId: product.id,
              });
            }

            if (isMany) {
              event.preventDefault();
              event.stopPropagation();

              return setOpen(type);
            }
          };

          let href = product.shareUrl;

          if (referralContentId) {
            href += `?referralContentId=${referralContentId}`;
          }

          return (
            <Grid item key={product.id}>
              <StyledButton
                variant="contained"
                color="primary"
                size={xs ? "medium" : "medium"}
                href={href}
                target="_blank"
                onClick={onClick}
              >
                <span>{text}</span>
                {button.minPrice.currency === "XTR" && (
                  <StarsIcon className={classes.star} />
                )}
              </StyledButton>
            </Grid>
          );
        })}
      </Grid>
      <ChooseProductDialog
        open={!!open}
        onClose={onClose}
        products={productsToDialog}
      />
    </>
  );
}

PaymentButtons.propTypes = {
  referralContentId: PropTypes.string,
  products: PropTypes.array,
  onClickProductButton: PropTypes.func,
  isTelegramArena: PropTypes.bool,
};
