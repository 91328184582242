import React, { cloneElement } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ToggleNotificationsProductPurchase from "arena/components/ToggleNotificationsProductPurchase";
import DonationProgress from "arena/components/DonationProgress";
// import Divider from "@mui/material/Divider";
// import ReferalPromocode from "./ReferalPromocode";
import { useApolloClient, gql } from "@apollo/client";
import formatCurrency from "core/utils/currency";
import getPricePeriodPer from "arena/utils/getPricePeriodPer";

const SUBSCRIPTION = "SUBSCRIPTION";
const PPV = "PPV";
const DONATION = "DONATION";
const PREFIX = "ProductPurchase";

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  [`& .${classes.appBar}`]: {
    // backdropFilter: "blur(12px)",
    backgroundColor: "rgba(0,0,0,.25)",
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
}));

function ProductPurchase({ product, closeRouteDialogButton, showShareUrl }) {
  const { t } = useTranslation("arena");
  const client = useApolloClient();

  if (!product?.viewerPurchase) return null;

  const { viewerPurchase } = product;
  const showProductFinished = product?.finished;

  const subheader = showProductFinished
    ? t("productCover.finished", {
        date: product.finishAt,
      })
    : viewerPurchase.allCyclesExpiredAt
    ? t("productPurchase.allCyclesExpiredAt", {
        allCyclesExpiredAt: viewerPurchase.allCyclesExpiredAt,
        purchaseCreatedAt: viewerPurchase.createdAt,
        purchaseExpiredAt: viewerPurchase.expiredAt,
      })
    : viewerPurchase.recurrent
    ? t("productPurchase.nextBilling", {
        purchaseExpiredAt: viewerPurchase.expiredAt,
      })
    : t("productPurchase.periodPpv", {
        purchaseStartAt: viewerPurchase.startAt,
        purchaseExpiredAt: viewerPurchase.expiredAt,
      });

  const onClickPayMore = () => {
    client.writeFragment({
      id: `Product:${product.id}`,
      fragment: gql`
        fragment MyProduct on Product {
          viewerPurchase {
            id
          }
        }
      `,
      data: {
        viewerPurchase: null,
      },
    });
  };

  let recurrentDescription;

  if (viewerPurchase.recurrent) {
    const price = formatCurrency(
      viewerPurchase.amount.value,
      viewerPurchase.amount.currency
    );
    const period = getPricePeriodPer({ t, period: viewerPurchase.period });
    const priceOnPeriod = `${price} ${period.toLowerCase()}`;

    recurrentDescription = t("productPayment.subscriptionDescription", {
      priceOnPeriod,
    });
  }

  const display =
    !viewerPurchase.started ||
    !!recurrentDescription ||
    (viewerPurchase.recurrent && !viewerPurchase.renewSubscription) ||
    subheader;

  return (
    <StyledCard className={classes.root} elevation={0}>
      <AppBar
        color="default"
        elevation={0}
        position="static"
        className={classes.appBar}
      >
        <CardHeader
          title={product.title}
          titleTypographyProps={{
            variant: "h5",
          }}
          sx={{ position: "relative" }}
          action={
            closeRouteDialogButton
              ? cloneElement(closeRouteDialogButton, {
                  position: "right",
                })
              : undefined
          }
        />
      </AppBar>
      <CardContent className={classes.content}>
        <Grid container spacing={2} justifyContent="center">
          {product?.donationProgress?.targetAmount && (
            <Grid item xs={12}>
              <DonationProgress product={product} />
            </Grid>
          )}
          {display && (
            <Grid item xs={12}>
              {product.type !== DONATION && (
                <Typography
                  component="div"
                  variant="body1"
                  color="textPrimary"
                  gutterBottom
                >
                  {subheader}
                </Typography>
              )}

              {!viewerPurchase.started && (
                <Typography
                  component="div"
                  variant="body1"
                  color="textPrimary"
                  gutterBottom
                >
                  {t("productPurchase.notStarted", {
                    date: viewerPurchase.startAt,
                  })}
                </Typography>
              )}
              {recurrentDescription && (
                <Typography
                  component="div"
                  variant="body1"
                  color="textPrimary"
                  gutterBottom
                >
                  {recurrentDescription}
                </Typography>
              )}
              {viewerPurchase.recurrent && !viewerPurchase.renewSubscription && (
                <Typography component="div" variant="body1" color="textPrimary">
                  {t("productPurchase.renewCanceled")}
                </Typography>
              )}
            </Grid>
          )}

          {product.type === DONATION && (
            <Grid item xs={12}>
              <Button
                onClick={onClickPayMore}
                variant="contained"
                color="primary"
              >
                {t("productPurchase.oneMoreDonation")}
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            <ToggleNotificationsProductPurchase
              productPurchase={viewerPurchase}
            />
          </Grid>

          {showShareUrl && viewerPurchase.telegramShareUrl && (
            <Grid item xs={12}>
              <Button
                href={viewerPurchase.telegramShareUrl}
                variant="contained"
                color="primary"
                target="_blank"
                fullWidth
              >
                {t("productPurchase.openMiniApp")}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
      {/* <ReferalPromocode product={product} classes={classes} /> */}
    </StyledCard>
  );
}

ProductPurchase.propTypes = {
  showShareUrl: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    shareUrl: PropTypes.string.isRequired,
    finished: PropTypes.bool,
    startAt: PropTypes.string.isRequired,
    finishAt: PropTypes.string.isRequired,
    composition: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.oneOf([SUBSCRIPTION, PPV, DONATION]).isRequired,
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      sellerInfo: PropTypes.string,
    }).isRequired,
    donationProgress: PropTypes.object,
    preview: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    viewerPurchase: PropTypes.shape({
      id: PropTypes.string.isRequired,
      telegramShareUrl: PropTypes.string,
      renewSubscription: PropTypes.bool,
      type: PropTypes.oneOf([SUBSCRIPTION, PPV, DONATION]).isRequired,
      recurrent: PropTypes.bool,
      started: PropTypes.bool,
      expired: PropTypes.bool,
      startAt: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      expiredAt: PropTypes.string.isRequired,
      allCyclesExpiredAt: PropTypes.string,
      period: PropTypes.number.isRequired,
      daysLeft: PropTypes.number.isRequired,
      promocode: PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
      amount: PropTypes.shape({
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }),
    }),
  }),
  closeRouteDialogButton: PropTypes.node,
};

export default ProductPurchase;
