import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import CardHeader from "@mui/material/CardHeader";
import ProductDescription from "arena/components/ProductCover/ProductDescription";

export default function ProductBody({ product }) {
  const { t } = useTranslation("arena");

  const viewerPurchase = product?.viewerPurchase;

  return (
    <Card
      elevation={0}
      square
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar
        color="default"
        elevation={0}
        position="static"
        sx={{ backgroundColor: "rgba(0,0,0,.25)" }}
      >
        <CardHeader title={t("productCover.title")} />
      </AppBar>

      {product.description && (
        <ProductDescription viewerPurchase={viewerPurchase} product={product} />
      )}
    </Card>
  );
}

ProductBody.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
    viewerPurchase: PropTypes.object,
  }),
};
