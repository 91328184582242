import React from "react";
import PropTypes from "prop-types";
import ArenaHelmet from "arena/components/ArenaHelmet";

export default function DigitalAssetHelmet({ digitalAsset }) {
  const imageUrl = digitalAsset?.image?.url;

  return (
    <ArenaHelmet
      title={digitalAsset.title}
      description={digitalAsset.description}
      imageUrl={imageUrl}
    />
  );
}

DigitalAssetHelmet.propTypes = {
  digitalAsset: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
};
