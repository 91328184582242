import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Likes from "arena/components/Likes";
import FavoriteButton from "arena/components/FavoriteButton";
import ShareButton from "arena/components/ShareButton";

export default function ContentControls({
  contentId,
  isFavoritesEnabled,
  isLikesEnabled,
  isShareEnabled,
  shareData,
  likes,
}) {
  return (
    <Box
      sx={{
        overflowX: "auto",
        width: "100%",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        pl: {
          xs: 2,
          sm: 3,
        },
        pr: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      <Paper
        elevation={0}
        sx={{ display: "inline-flex", borderRadius: 50, overflow: "hidden" }}
      >
        <Stack direction="row" spacing={0} alignItems="center">
          {isLikesEnabled && (
            <Likes contentId={contentId} likes={likes} borderRadius={0} />
          )}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ bgcolor: "background.body" }}
          />
          {isFavoritesEnabled && <FavoriteButton contentId={contentId} />}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ bgcolor: "background.body" }}
          />
          {isShareEnabled && <ShareButton shareData={shareData} />}
        </Stack>
      </Paper>
    </Box>
  );
}

ContentControls.propTypes = {
  contentId: PropTypes.string,
  isFavoritesEnabled: PropTypes.bool,
  isLikesEnabled: PropTypes.bool,
  isShareEnabled: PropTypes.bool,
  likes: PropTypes.object,
  shareData: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  }),
};
