import React from "react";
import PropTypes from "prop-types";
import ProductContents from "arena/components/ProductContents";
import LoadMore from "core/components/LoadMore";
import { useQuery } from "@apollo/client";
import { CollectionsQuery } from "arena/queries";
import ReactGA from "react-ga4";

const UPDATED = "UPDATED";

export default function Collections({
  productId,
  skip,
  sort = UPDATED,
  limit = 5,
}) {
  const { data, loading, fetchMore } = useQuery(CollectionsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      productId,
      skip,
      limit,
      sort,
    },
  });

  const count = data?.contents?.count || 0;
  const nodes = data?.contents?.nodes || [];

  if (nodes.length === 0) return null;

  const hasMore = nodes.length < count;
  const showLoadMore = hasMore && !loading && sort !== UPDATED;
  const nextPage = Math.ceil(nodes.length / limit);

  const onLoadMore = () => {
    ReactGA.event({
      category: "Collections",
      action: "Fetch more collections",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        skip: nextPage * limit,
      },
    });
  };

  return (
    <>
      {nodes.map((collection) => {
        return (
          <ProductContents
            key={collection.id}
            sort={sort}
            limit={24}
            collectionId={collection.id}
            title={collection.title}
            moreUrl={collection.siteUrl}
          />
        );
      })}

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </>
  );
}

Collections.propTypes = {
  productId: PropTypes.string,
  skip: PropTypes.number,
  limit: PropTypes.number,
  sort: PropTypes.string,
};
