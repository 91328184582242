import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import PlayerPanel from "core/components/PlayerPanel";
import FullscreenButton from "core/components/FullscreenButton";
import QualityButton from "core/components/QualityButton";
import MuteButton from "core/components/MuteButton";
import ShareButton from "core/components/ShareButton";
import LogoButton from "core/components/LogoButton";
import Duration from "core/components/Duration";
import LiveButton from "core/components/LiveButton";
import LiveSeekBar from "core/components/LiveSeekBar";
import VodSeekBar from "core/components/VodSeekBar";
import PauseButton from "core/components/PauseButton";
import PlayButton from "core/components/PlayButton";
import PipButton from "core/components/PipButton";
import RewindButton from "core/components/RewindButton";
import CurrentTime from "core/components/CurrentTime";
import { useMediaQuery } from "@mui/material";
import contentUrl from "core/utils/contentUrl";
import CastButton from "core/components/CastButton";
import SubtitlesButton from "core/components/SubtitlesButton";
import { isTelegramMiniApp } from "core/utils/telegram";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  width: "auto",
  position: "absolute",
  minHeight: 56,
  alignItems: "center",
  left: theme.spacing(1),
  right: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    left: theme.spacing(2),
    right: theme.spacing(2),
  },
  [theme.breakpoints.up("md")]: {
    left: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

const LeftControls = styled("div")({
  display: "flex",
});

const RightControls = styled("div")({
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
});

function PlayerFooter({
  start,
  finish,
  fullScreen,
  visible,
  position,
  videoElement,
  liveSeekBar,
  videoState,
  videoActions,
  content,
  onClickPlay,
  onClickPause,
  onSeekStart,
  onSeek,
  onSeekEnd,
  hideShare,
  hideLogo,
  fullscreenTarget,
  videoPreviews,
  chooseCameraButton,
  disableSeekBarPopup,
  hlsUrl,
}) {
  const isMobile = useMediaQuery("(max-width: 450px)");

  const showShare = isMobile ? false : hideShare ? false : !content?.hideShare;
  const showQuality = isMobile ? false : true;
  const showRewind = videoState?.live && !isMobile;
  const showLogo = hideLogo ? false : !content?.hideLogo;

  const onClickRewind = () => {
    liveSeekBar.setOffset(liveSeekBar.offset + 10);
  };

  return (
    <>
      <PlayerPanel visible={visible} position={position} disableGutters>
        <Root>
          <LeftControls>
            <PlayButton videoState={videoState} onClick={onClickPlay} />
            <PauseButton videoState={videoState} onClick={onClickPause} />

            {showRewind && (
              <RewindButton videoState={videoState} onClick={onClickRewind} />
            )}

            {chooseCameraButton}

            <LiveButton
              videoState={videoState}
              videoActions={videoActions}
              liveSeekBar={liveSeekBar}
            />
            <CurrentTime liveSeekBar={liveSeekBar} />
            <Duration videoState={videoState} />
          </LeftControls>

          <RightControls>
            <MuteButton videoState={videoState} videoActions={videoActions} />

            {!isTelegramMiniApp && (
              <SubtitlesButton
                videoState={videoState}
                videoActions={videoActions}
              />
            )}

            {showQuality && (
              <QualityButton
                videoState={videoState}
                videoActions={videoActions}
              />
            )}
            {showShare && !isTelegramMiniApp && (
              <ShareButton
                shareUrlParams={{ contentId: content.id }}
                shareUrl={contentUrl(content.shareUrl || content.url, {
                  start,
                  finish,
                })}
                embedUrl={contentUrl(content.url, {
                  autoplay: 1,
                  loop: 1,
                  start,
                  finish,
                })}
                shareText={content.title}
                shareDescription={content.rightholder.name}
                shareImage={content.preview}
              />
            )}
            {showLogo && <LogoButton url={content.shareUrl} />}
            {!isTelegramMiniApp && <PipButton videoElement={videoElement} />}
            {content && (
              <CastButton
                liveSeekBar={liveSeekBar}
                content={content}
                hlsUrl={hlsUrl}
              />
            )}

            {fullScreen && (
              <FullscreenButton
                videoElement={videoElement}
                target={fullscreenTarget}
              />
            )}
          </RightControls>
          <VodSeekBar
            videoState={videoState}
            videoActions={videoActions}
            videoPreviews={videoPreviews}
            visible={visible}
            onSeekStart={onSeekStart}
            onSeek={onSeek}
            onSeekEnd={onSeekEnd}
            disableSeekBarPopup={disableSeekBarPopup}
          />

          <LiveSeekBar
            liveSeekBar={liveSeekBar}
            videoState={videoState}
            videoActions={videoActions}
            visible={visible}
          />
        </Root>
      </PlayerPanel>
    </>
  );
}

PlayerFooter.propTypes = {
  fullscreenTarget: PropTypes.string,
  hideShare: PropTypes.bool,
  hideLogo: PropTypes.bool,
  fullScreen: PropTypes.bool,
  visible: PropTypes.bool,
  position: PropTypes.string,
  liveSeekBar: PropTypes.object,
  videoPreviews: PropTypes.object,
  hlsUrl: PropTypes.string,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    logo: PropTypes.string,
    shareUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    preview: PropTypes.string,
    hideShare: PropTypes.bool,
    hideLogo: PropTypes.bool,
    rightholder: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hideLogo: PropTypes.bool, // unused
      hideShare: PropTypes.bool, // unused
    }),
  }),
  videoElement: PropTypes.node,
  videoState: PropTypes.object,
  videoActions: PropTypes.object,
  onClickPlay: PropTypes.func,
  onClickPause: PropTypes.func,
  onSeekStart: PropTypes.func,
  onSeek: PropTypes.func,
  onSeekEnd: PropTypes.func,
  disableSeekBarPopup: PropTypes.bool,
  chooseCameraButton: PropTypes.node,
  hideRelatedContent: PropTypes.bool,
  start: PropTypes.number,
  finish: PropTypes.number,
};

PlayerFooter.defaultProps = {
  fullScreen: true,
};

export default PlayerFooter;
