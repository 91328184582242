import { useEffect } from "react";

export default function useVideoPostMessages({ videoActions }) {
  useEffect(() => {
    if (!process.browser) return;

    const cb = (event) => {
      if (!event?.data?.actionName) return;

      switch (event.data.actionName) {
        case "play":
          videoActions.play();
          break;
        case "pause":
          videoActions.pause();
          break;
        case "mute":
          videoActions.mute();
          break;
        case "unmute":
          videoActions.unmute();
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", cb);

    return () => {
      window.removeEventListener("message", cb);
    };
  }, []);
}
