import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function ContentMetrics({ content }) {
  const { t } = useTranslation("arena");

  const viewsMetric = {
    VIDEO: t("contentBody.totalViews", { count: content.totalViews || 1 }),
    STREAM: t("contentBody.viewersOnline", {
      count: content.viewersOnline || 1,
    }),
  }[content.type];

  const publishedMetric = t("contentBody.published", {
    date: content.publishAt,
  });

  const metrics = [viewsMetric, publishedMetric];

  if (content.ageRestrictions) {
    metrics.push(`${content.ageRestrictions}+`);
  }

  return (
    <Box
      sx={{
        pl: {
          xs: 2,
          sm: 3,
        },
        pr: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      <Breadcrumbs separator="•">
        {metrics.map((metric, index) => (
          <Typography key={index} variant="body2">
            {metric}
          </Typography>
        ))}
      </Breadcrumbs>
    </Box>
  );
}

ContentMetrics.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string.isRequired,
    totalViews: PropTypes.number.isRequired,
    viewersOnline: PropTypes.number.isRequired,
    publishAt: PropTypes.string.isRequired,
    ageRestrictions: PropTypes.number,
  }).isRequired,
};
