import React from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CommentListItemControls from "./CommentListItemControls";
import { useTranslation } from "react-i18next";
import { truncate } from "core/utils/string";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import CommentListItemText from "./CommentListItemText";
import CommentListItemReplyTo from "./CommentListItemReplyTo";

export default function CommentListItem({
  comment,
  viewer,

  onClickReply,
  onClickMore,
}) {
  const { t } = useTranslation("arena");

  const isCreator = viewer?.id === comment.creator.id;
  const canManage = isCreator || viewer?.isArenaAdmin;
  const canReply = !isCreator && !!viewer;

  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        pl: { xs: 1, sm: 2 },
        // pr: 1,
        // bgcolor: isCreator ? "primary.dark" : "transparent",
      }}
    >
      <ListItemAvatar sx={{ minWidth: { xs: 44, sm: 56 } }}>
        <Avatar
          src={comment.creator.photo?.url || comment.creator?.photoUrl}
          sx={{
            width: {
              xs: 34,
              sm: 40,
            },
            height: {
              xs: 34,
              sm: 40,
            },
          }}
        >
          {comment.creator.fullName?.[0]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box sx={{ display: "flex", alignItems: "center", minHeight: 34 }}>
            <Typography
              variant="subtitle2"
              color={isCreator ? "primary" : "secondary"}
              display="inline"
            >
              {truncate(comment.creator.fullName, 20)}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              display="inline"
              sx={{ ml: 1, mr: "auto" }}
            >
              {t("commentListItem.createdAt", { date: comment.createdAt })}
            </Typography>
            {canManage && (
              <IconButton size="small" onClick={() => onClickMore(comment)}>
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        }
        secondary={
          <>
            {comment.replyToComment && (
              <CommentListItemReplyTo comment={comment.replyToComment} />
            )}
            <CommentListItemText message={comment.message} />

            <CommentListItemControls
              canReply={canReply}
              onClickReply={onClickReply}
              comment={comment}
            />
          </>
        }
        disableTypography
      />
    </ListItem>
  );
}

CommentListItem.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creator: PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      photoUrl: PropTypes.string,
      photo: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }).isRequired,
    message: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    replyToComment: PropTypes.object,
  }).isRequired,
  canManage: PropTypes.bool,
  onClickMore: PropTypes.func.isRequired,
  onClickReply: PropTypes.func.isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isArenaAdmin: PropTypes.bool,
  }),
};
