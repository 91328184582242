import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { Link } from "react-router-dom";
import useGuidUrl from "arena/hooks/useGuidUrl";

const StyledButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1200,
  flexShrink: 0,
  fontSize: "0.79rem",
  padding: theme.spacing(0.5, 1),

  ["@media (min-width: 375px)"]: {
    top: theme.spacing(2),
    right: theme.spacing(2),
  },

  [theme.breakpoints.up("sm")]: {
    fontSize: "0.875rem",
    padding: theme.spacing(1, 2),
  },

  [theme.breakpoints.up("md")]: {
    top: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

function PromoButton({ url, text, color, textColor, onClick, visible }) {
  const style = { backgroundColor: color, color: textColor };

  const resultLink = useGuidUrl(url);
  const isInternalLink = resultLink?.startsWith("/");

  const component = isInternalLink ? Link : "a";

  return (
    <Slide direction="down" in={visible} appear={false}>
      <StyledButton
        component={component}
        variant="contained"
        color="primary"
        to={resultLink}
        href={resultLink}
        target={isInternalLink ? undefined : "_blank"}
        rel="noopener noreferrer"
        style={style}
        onClick={onClick}
      >
        {text}
      </StyledButton>
    </Slide>
  );
}

PromoButton.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};

export default PromoButton;
