import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { truncate } from "core/utils/string";

export default function CommentListItemReplyTo({ comment }) {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "rgba(0, 0, 0, 0.1)",
        borderLeft: "2px solid",
        borderColor: "secondary.main",
        pl: 1,
        mb: 1,
        maxWidth: "calc(100vw - 100px)",
      }}
    >
      <Typography variant="caption" color="secondary" display={"block"}>
        {comment.creator.fullName}
      </Typography>
      <Typography
        variant="caption"
        color="text.secondary"
        display={"block"}
        noWrap
      >
        {truncate(comment.message, 40)}
      </Typography>
    </Box>
  );
}

CommentListItemReplyTo.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    creator: PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
