import React from "react";
import { styled } from "@mui/material/styles";
import usePageview from "core/hooks/usePageview";
import useScrollToTop from "core/hooks/useScrollToTop";
import { useTranslation } from "react-i18next";
import { useArena } from "core/components/ArenaProvider";
import { TagQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import TextField from "@mui/material/TextField";
import { useLocation, useSearchParams } from "react-router-dom";
import ArenaHelmet from "arena/components/ArenaHelmet";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import CloseIcon from "@mui/icons-material/Close";
import HomepageTitle from "arena/components/HomepageTitle";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import LoadMore from "core/components/LoadMore";
import {
  ArenaContentsQuery,
  ArenaPostsQuery,
  DigitalAssetsQuery,
  ArenaSearchQuery,
} from "arena/queries";

import ContentGrid from "arena/components/ContentGrid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import PostGrid from "arena/components/ArenaPosts/Grid";
import DigitalAssetGrid from "arena/components/ArenaDigitalAssets/Grid";

const PREFIX = "Search";

const classes = {
  form: `${PREFIX}-form`,
  list: `${PREFIX}-list`,
};

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.form}`]: {
    padding: theme.spacing(2, 2, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 0, 0),
    },
  },

  [`& .${classes.list}`]: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 3),
    },
  },
}));

export default function Search() {
  const limit = 18;
  const arena = useArena();
  const location = useLocation();
  const { t } = useTranslation("arena");

  const itemsPerRow = arena.defaultRowSize;

  usePageview(location.pathname, "Search");
  useScrollToTop();

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const tab = searchParams.get("tab") || "contents";

  const tagId = searchParams.get("tagId");
  const tagIds = searchParams.getAll("tagId");

  const { data: arenaData } = useQuery(ArenaSearchQuery, {
    fetchPolicy: "no-cache",
  });

  const hasDigitalAssets = arenaData?.digitalAssets?.count > 0;
  const hasPosts = arenaData?.posts?.count > 0;

  const { data } = useQuery(TagQuery, {
    skip: !tagId,
    variables: {
      id: tagId,
    },
  });

  const tag = data?.tag?.title;

  const handleChangeTab = (event, newValue) => {
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
  };

  const onClear = () => {
    searchParams.delete("query");

    return setSearchParams(searchParams);
  };

  const handleChange = (event) => {
    searchParams.set("query", event.target.value);
    setSearchParams(searchParams);
  };

  const variables = {
    search: query,
    sort: "LATEST",
    type: "VIDEO",
    limit: 18,
    tagIds,
  };

  const {
    data: contentsData,
    loading: contentsLoading,
    fetchMore: fetchMoreContents,
  } = useQuery(ArenaContentsQuery, {
    fetchPolicy: "cache-and-network",
    variables,
  });

  const {
    data: digitalAssetsData,
    loading: digitalAssetsLoading,
    fetchMore: fetchMoreDigitalAssets,
  } = useQuery(DigitalAssetsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      limit: 18,
      filter: {
        search: query,
        tagIds,
      },
    },
  });

  const {
    data: postsData,
    loading: postsLoading,
    fetchMore: fetchMorePosts,
  } = useQuery(ArenaPostsQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      limit: 18,
      search: query,
    },
  });

  const count =
    tab === "contents"
      ? contentsData?.contents?.count || 0
      : tab === "posts"
      ? postsData?.posts?.count || 0
      : digitalAssetsData?.digitalAssets?.count || 0;

  const nodes =
    tab === "contents"
      ? contentsData?.contents?.nodes || []
      : tab === "posts"
      ? postsData?.posts?.nodes || []
      : digitalAssetsData?.digitalAssets?.nodes || [];

  const hasMore = nodes.length < count;
  const loading = contentsLoading || digitalAssetsLoading || postsLoading;

  const fetchMore =
    tab === "contents"
      ? fetchMoreContents
      : tab === "posts"
      ? fetchMorePosts
      : fetchMoreDigitalAssets;

  const tabs = ["contents"];

  if (hasPosts) {
    tabs.push("posts");
  }

  if (hasDigitalAssets) {
    tabs.push("digitalAssets");
  }

  const tabLabels = {
    contents: t("search.contents", {
      _count: contentsData?.contents?.count || 0,
    }),
    posts: t("search.posts", {
      _count: postsData?.posts?.count || 0,
    }),
    digitalAssets: t("search.digitalAssets", {
      _count: digitalAssetsData?.digitalAssets?.count || 0,
    }),
  };

  const showLoadMore = hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / limit);

  const onLoadMore = () => {
    fetchMore({
      variables: {
        skip: nextPage * limit,
      },
    });
  };

  const xs = 6;
  const sm = Math.max(12 / itemsPerRow, 4);
  const md = Math.max(12 / itemsPerRow, 3);
  const lg = 12 / itemsPerRow;
  const xl = lg;
  const empty = !loading && count === 0;

  const Component =
    tab === "contents"
      ? ContentGrid
      : tab === "posts"
      ? PostGrid
      : DigitalAssetGrid;

  return (
    <Root>
      <ArenaHelmet title={tag || t("search.result")} />

      <Box className={classes.form}>
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          value={query}
          onChange={handleChange}
          placeholder={t("search.placeholder")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClear}>
                  {query ? <CloseIcon /> : <SearchIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <HomepageTitle sx={{ mt: -1 }} title={tag || t("search.result")} />

      {tabs.length > 1 && (
        <Box sx={{ mt: -1, mb: 2 }}>
          <Tabs value={tab} onChange={handleChangeTab}>
            {tabs.map((key) => (
              <Tab key={key} label={tabLabels[key]} value={key} />
            ))}
          </Tabs>
        </Box>
      )}

      {empty ? (
        <Stack
          spacing={2}
          sx={{ mt: 5 }}
          alignItems="center"
          justifyContent="center"
        >
          <SearchOffIcon sx={{ fontSize: "86px" }} />

          <Typography variant="h6">{t("search.empty")}</Typography>

          <Typography variant="body1" color="textSecondary">
            {t("search.emptyDescription")}
          </Typography>
        </Stack>
      ) : loading ? (
        <Stack
          spacing={2}
          sx={{ mt: 5 }}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size="86px" />
        </Stack>
      ) : (
        <Box>
          <Component
            nodes={nodes}
            items={nodes}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            itemsPerRow={itemsPerRow}
          />

          {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
        </Box>
      )}
    </Root>
  );
}
