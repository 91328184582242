import React, { Fragment } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CommentListItem from "./CommentListItem";

export default function CommentList({
  comments,
  onClickReply,
  onClickMore,
  viewer,
  loadMore,
}) {
  return (
    <List
      disablePadding
      id="comments-scrollable"
      sx={{
        overflowY: "auto",
        height: "100%",
      }}
    >
      {comments.map((comment, index) => (
        <Fragment key={comment.id}>
          {index > 0 && (
            <Divider
              variant="fullWidth"
              sx={{ borderColor: "background.body" }}
            />
          )}
          <CommentListItem
            comment={comment}
            onClickReply={onClickReply}
            onClickMore={onClickMore}
            viewer={viewer}
          />
        </Fragment>
      ))}
      {loadMore}
    </List>
  );
}

CommentList.propTypes = {
  loadMore: PropTypes.node,
  viewer: PropTypes.object,
  comments: PropTypes.array.isRequired,
  onClickReply: PropTypes.func.isRequired,
  onClickMore: PropTypes.func.isRequired,
};
