import React, { useState } from "react";
import PropTypes from "prop-types";
import Video from "core/components/Video";
import { postMessage } from "core/utils/tracker";
import RelatedVideo from "core/components/RelatedVideo";
import { createEvent } from "core/utils/tracker";
import debounce from "core/utils/debounce";
import ChooseCameraButton from "core/components/ChooseCameraButton";
import CastVideoScreen from "core/components/CastVideoScreen";
import { useCast } from "core/components/CastProvider";
import VideoScreenContent from "core/components/VideoScreenContent";

// если пользователь досмотрел почти до конца(10с), будем стартовать видео с начала
const VIDEO_VIEWER_POSITION_DIFF_LIMIT = 10;

// Дергаем событие не чаще чем раз в 60 секунд
const createEvent60000 = debounce(createEvent, 60 * 1000);

export default function VideoScreen({
  closeRouteDialogButton,
  controls,
  nextPlaylistContentButton,
  onPlay,
  onPause,
  onEnded,
  onLastQuartileStart,
  view,
  content,
  video,
  hideTitle,
  hideShare,
  hideLogo,
  autoPause,
  autoPlay,
  muted,
  loop,
  hideRelatedContent,
  fullscreenTarget,
  updateVideoPosition,
  records,
  start,
  finish,
  sponsorCornerBannerPlace,
  sponsorPlayerLogoPlace,
  sponsorPlayerButtonPlace,
  sponsorInStreamBannerPlace,
  sponsorVideoPlace,
  onSeeked,
  onTimeUpdate,
  onSeekEnd,
  onUpdate,
  viewDataTracker,
  onVideoError,
  coverFitMode,
}) {
  const [cornerBannerHeight, setCornerBannerHeight] = useState(null);
  const [inStreamBannerHeight, setInStreamBannerHeight] = useState(null);
  const [pause, setPause] = useState(false);
  const [trackedEvents, setTrackedEvents] = useState([]);
  const [isSeeking, setIsSeeking] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false);
  const [seekValue, setSeekValue] = useState(0);
  const [recordId, setRecordId] = useState(records ? records[0].id : null);

  const cast = useCast();

  const currentVideo =
    (records && records.find((record) => record.id === recordId)) || video;

  const [_finish, setFinish] = useState(finish);
  const [_start, setStart] = useState(
    start ||
      (currentVideo.viewerVideoPosition + VIDEO_VIEWER_POSITION_DIFF_LIMIT >=
      currentVideo.duration
        ? 0
        : currentVideo.viewerVideoPosition)
  );

  const handleEnded = (vid) => {
    if (!trackedEvents.includes("end")) {
      createEvent("video", "end");
      setTrackedEvents((trackedEvents) => trackedEvents.concat("end"));
      postMessage({
        eventName: "END",
        contentType: "video",
      });
    }

    if (loop) {
      if (!content.isAdvEnabled) {
        // Если реклама на контенте правообладателя выключена, то запускаем повторное воспроизведение сразу после завершения показа
        // а если реклама включена, то повторное воспроизведение будет запущено ниже с помощью onFinishPostroll
        vid.play();
      }
    }

    if (onEnded) {
      onEnded();
    }

    const videoState = vid.videoState;

    const updateData = {};

    if (videoState.played) {
      updateData.trackDuration = {
        secondsIntervals: videoState.played.map(([from, to]) => {
          return {
            from,
            to,
          };
        }),
      };
    }

    if (updateVideoPosition) {
      updateData.videoPosition = {
        currentTime: currentVideo.duration,
        videoId: currentVideo.id,
        percent: 100,
      };
    }

    viewDataTracker.track(updateData);
  };

  const _onPlay = () => {
    if (onPlay) {
      onPlay();
    }
    if (pause) {
      setPause(false);
    }
    if (!trackedEvents.includes("start")) {
      createEvent("video", "start");
      setTrackedEvents((trackedEvents) => trackedEvents.concat("start"));
      postMessage({
        eventName: "START",
        contentType: "video",
      });
    }
  };

  const handleUpdate = ({ currentTime, duration, played }) => {
    if (onUpdate) {
      onUpdate({ currentTime, duration, played });
    }

    if (_finish) {
      if (currentTime > _finish) {
        if (onEnded) {
          onEnded();
        }
      }
    }

    const percent = (100 / duration) * currentTime;

    if (percent > 75 && !trackedEvents.includes("thirdQuartile")) {
      createEvent("video", "thirdQuartile");
      setTrackedEvents((trackedEvents) =>
        trackedEvents.concat("thirdQuartile")
      );
      postMessage({
        eventName: "THIRDQUARTILE",
        contentType: "video",
      });
      if (onLastQuartileStart) {
        onLastQuartileStart();
      }
    } else if (percent > 50 && !trackedEvents.includes("midpoint")) {
      createEvent("video", "midpoint");
      setTrackedEvents((trackedEvents) => trackedEvents.concat("midpoint"));
      postMessage({
        eventName: "MIDPOINT",
        contentType: "video",
      });
    } else if (percent > 25 && !trackedEvents.includes("firstQuartile")) {
      createEvent("video", "firstQuartile");
      setTrackedEvents((trackedEvents) =>
        trackedEvents.concat("firstQuartile")
      );
      postMessage({
        eventName: "FIRSTQUARTILE",
        contentType: "video",
      });
    } else {
      createEvent60000("video", "watch");
    }

    const updateData = {
      trackDuration: {
        secondsIntervals: played.map(([from, to]) => {
          return {
            from,
            to,
          };
        }),
      },
    };

    if (updateVideoPosition) {
      setStart(currentTime);
      updateData.videoPosition = {
        currentTime,
        videoId: currentVideo.id,
        percent,
      };
    }

    if (played) {
      updateData.trackDuration = {
        secondsIntervals: played.map(([from, to]) => {
          return {
            from,
            to,
          };
        }),
      };
    }

    viewDataTracker.trackDebounce(updateData);
  };

  const onVideoUnmount = ({ currentTime, duration, played }) => {
    if (!played || !played.length) {
      return;
    }

    const updateData = {
      trackDuration: {
        secondsIntervals: played.map(([from, to]) => {
          return {
            from,
            to,
          };
        }),
      },
    };

    if (updateVideoPosition && currentTime) {
      const percent = (100 / duration) * currentTime;

      updateData.videoPosition = {
        currentTime,
        videoId: currentVideo.id,
        percent,
      };
    }

    /**
     * Вызываться будет при unmount компонента, поэтому
     * делать setSendPlayed нет смысла
     */
    viewDataTracker.track(updateData);
  };

  const handleSeekStart = (value, videoState, videoActions) => {
    setIsSeeking(true);
    setSeekValue(value);
    setWasPlaying(!videoState.paused);
    videoActions.pause();
  };

  const handleSeek = (value) => {
    setSeekValue(value);
  };

  const handleSeekEnd = (value, videoState, videoActions) => {
    videoActions.navigate(value);

    if (onSeekEnd) {
      onSeekEnd(value, videoState, videoActions);
    }

    if (wasPlaying) {
      videoActions.play();
    }

    setIsSeeking(false);

    if (finish) {
      if (value > finish) {
        setFinish(undefined);
      }
    }
  };

  const chooseCameraButton =
    records && records.length > 1 ? (
      <ChooseCameraButton
        cameras={records}
        cameraId={recordId}
        setCameraId={setRecordId}
      />
    ) : undefined;

  const relatedContent =
    !content.hideRelatedContent && !hideRelatedContent ? (
      <RelatedVideo />
    ) : null;

  if (cast.currentContentId === content.id && cast.playerState) {
    /**
     * @TODO нужно из каста как то научится добывать played для updateViewData
     * @TODO нужно синхронизировать seek: либо передавать все функции сюда либо делать хук useSeekBar и его передавать
     */
    return (
      <CastVideoScreen
        closeRouteDialogButton={closeRouteDialogButton}
        content={content}
        hideTitle={hideTitle}
        hideShare={hideShare}
        hideLogo={hideLogo}
      />
    );
  }

  return (
    <Video
      blur={pause}
      start={_start}
      finish={_finish}
      muted={muted}
      autoPlay={autoPlay}
      hlsUrl={currentVideo.playlist}
      drm={currentVideo.drmPlayerSettings}
      onEnded={handleEnded}
      onUpdate={handleUpdate}
      onTimeUpdate={onTimeUpdate}
      onSeeked={onSeeked}
      updateInterval={100}
      onPlay={_onPlay}
      onPause={onPause}
      mediaId={currentVideo.id}
      viewId={view.id}
      onUnmount={onVideoUnmount}
      onError={onVideoError}
      content={content}
      coverFitMode={coverFitMode}
    >
      {(videoElement, videoState, videoActions, videoRef) => {
        return (
          <VideoScreenContent
            nextPlaylistContentButton={nextPlaylistContentButton}
            autoPlay={autoPlay}
            videoElement={videoElement}
            videoState={videoState}
            videoActions={videoActions}
            relatedContent={relatedContent}
            chooseCameraButton={chooseCameraButton}
            handleSeekEnd={handleSeekEnd}
            handleSeek={handleSeek}
            handleSeekStart={handleSeekStart}
            seekValue={seekValue}
            isSeeking={isSeeking}
            inStreamBannerHeight={inStreamBannerHeight}
            setInStreamBannerHeight={setInStreamBannerHeight}
            cornerBannerHeight={cornerBannerHeight}
            setCornerBannerHeight={setCornerBannerHeight}
            videoRef={videoRef}
            sponsorCornerBannerPlace={sponsorCornerBannerPlace}
            sponsorPlayerLogoPlace={sponsorPlayerLogoPlace}
            sponsorPlayerButtonPlace={sponsorPlayerButtonPlace}
            sponsorInStreamBannerPlace={sponsorInStreamBannerPlace}
            sponsorVideoPlace={sponsorVideoPlace}
            autoPause={autoPause}
            fullscreenTarget={fullscreenTarget}
            content={content}
            muted={muted}
            pause={pause}
            hideTitle={hideTitle}
            loop={loop}
            controls={controls}
            hideShare={hideShare}
            hideLogo={hideLogo}
            video={currentVideo}
            start={start}
            setPause={setPause}
            finish={finish}
            onVideoError={onVideoError}
            closeRouteDialogButton={closeRouteDialogButton}
          />
        );
      }}
    </Video>
  );
}

VideoScreen.defaultProps = {
  start: 0,
};

VideoScreen.propTypes = {
  onVideoError: PropTypes.func,
  nextPlaylistContentButton: PropTypes.func,
  onLastQuartileStart: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnded: PropTypes.func,
  fullscreenTarget: PropTypes.string,
  updateVideoPosition: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hideShare: PropTypes.bool,
  hideLogo: PropTypes.bool,
  hideRelatedContent: PropTypes.bool,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPause: PropTypes.bool,
  triggerPostMessage: PropTypes.bool,
  coverFitMode: PropTypes.bool,
  view: PropTypes.shape({
    id: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  }).isRequired,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    widget: PropTypes.object,
    enableViewerIdWatermark: PropTypes.bool,

    relatedContent: PropTypes.arrayOf(PropTypes.object),
    hideRelatedContent: PropTypes.bool,
    isAdvEnabled: PropTypes.bool,
    rightholder: PropTypes.shape({
      id: PropTypes.string,
      hideRelatedContent: PropTypes.bool,
    }),
    sport: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      shareUrl: PropTypes.string,
    }),
    hideLogo: PropTypes.bool,
  }),
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    playlist: PropTypes.string.isRequired,
    duration: PropTypes.number,
    viewerVideoPosition: PropTypes.number,
    videoPreviews: PropTypes.object,
  }),
  records: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      playlist: PropTypes.string.isRequired,
      duration: PropTypes.number,
      viewerVideoPosition: PropTypes.number,
      videoPreviews: PropTypes.object,
    })
  ),

  start: PropTypes.number,
  finish: PropTypes.number,
  sponsorPlayerLogoPlace: PropTypes.string,
  sponsorPlayerButtonPlace: PropTypes.string,
  sponsorInStreamBannerPlace: PropTypes.string,
  sponsorCornerBannerPlace: PropTypes.string,
  sponsorVideoPlace: PropTypes.string,
  onTimeUpdate: PropTypes.func,
  onSeeked: PropTypes.func,
  onSeekEnd: PropTypes.func,
  onUpdate: PropTypes.func,
  viewDataTracker: PropTypes.object.isRequired,
  closeRouteDialogButton: PropTypes.node,
};
