import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { TagTypesQuery, TagsQuery } from "arena/queries";
import ArenaContents from "arena/components/ArenaContents";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import HomepageTitle from "arena/components/HomepageTitle";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import indexById from "core/utils/indexById";
import TagsAutocomplete from "arena/components/TagsAutocomplete";

const NULL = "NULL";
const LIMIT = 12;

export default function ArenaArchive({
  title,
  filterTagTypes = [],
  tagTypeId,
  tagsSort,
  arena,
  allVideosText,
  rightholderIds,
  filterTagsBySelectedTags,
  itemsPerRow,
  tagIds,
}) {
  const { t } = useTranslation("arena");
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") || NULL;
  const queryTagIds = searchParams.getAll("tagId") || [];

  const { data } = useQuery(TagTypesQuery, {
    variables: {
      ids: filterTagTypes.map((item) => item.tagTypeId).concat(tagTypeId),
    },
  });

  const tagTypesById = indexById(data?.tagTypes);

  const quickLinkTagType = tagTypesById[tagTypeId];

  const tagTypesForFilter = filterTagTypes
    .map((item) => ({
      tagType: tagTypesById[item.tagTypeId],
      sort: item.sort,
    }))
    .filter((item) => item.tagType);

  const { data: quickLinkTagsData } = useQuery(TagsQuery, {
    skip: !quickLinkTagType,
    variables: {
      tagTypeId: quickLinkTagType?.id,
      sort: tagsSort,
    },
  });

  const quickLinkTags = quickLinkTagsData?.tags || [];

  const onChangeAutocomplete = (ids, newValue) => {
    const newTagIds = queryTagIds
      .filter((id) => !ids.includes(id))
      .concat(newValue?.value)
      .filter(Boolean);

    searchParams.delete("tagId");
    newTagIds.forEach((tagId) => searchParams.append("tagId", tagId));
    setSearchParams(searchParams);
  };

  const resultTagIds = Array.from(queryTagIds);

  if (tagIds?.length) {
    resultTagIds.push(...tagIds);
  }

  if (tab !== NULL) {
    resultTagIds.push(tab);
  }

  const onChangeTab = (event, newTab) => {
    if (newTab === NULL) {
      searchParams.delete("tab");
    } else {
      searchParams.set("tab", newTab);
    }
    setSearchParams(searchParams);
  };

  const displayTabs = quickLinkTags.length > 0 || tagTypesForFilter.length > 0;

  return (
    <>
      {title && <HomepageTitle title={title} />}

      <Box sx={!title ? { mt: 2 } : {}}>
        {displayTabs && (
          <Grid container spacing={2}>
            {quickLinkTags.length > 0 && (
              <Grid item xs={12}>
                <Tabs
                  value={tab}
                  onChange={onChangeTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  indicatorColor="primary"
                  textColor="inherit"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    value={NULL}
                    label={allVideosText || t("allVideos")}
                    sx={{ fontSize: "1.1rem" }}
                  />
                  {quickLinkTags.map((tag) => (
                    <Tab
                      value={tag.id}
                      label={tag.title}
                      key={tag.id}
                      sx={{ fontSize: "1.1rem" }}
                    />
                  ))}
                </Tabs>
              </Grid>
            )}
            {tagTypesForFilter.length > 0 && (
              <Grid item xs={12}>
                <Box sx={{ pl: { xs: 2, sm: 0 }, pr: { xs: 2, sm: 0 } }}>
                  <Grid container spacing={2}>
                    {tagTypesForFilter?.map(({ tagType, sort }) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={tagType.id}
                        >
                          <TagsAutocomplete
                            onChangeAutocomplete={onChangeAutocomplete}
                            tagIds={resultTagIds}
                            tagType={tagType}
                            sort={sort}
                            filterTagsBySelectedTags={filterTagsBySelectedTags}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        )}

        {displayTabs && <br />}

        <ArenaContents
          arena={arena}
          view="GRID"
          type="VIDEO"
          sort="LATEST"
          limit={LIMIT}
          tagIds={resultTagIds}
          rightholderIds={rightholderIds}
          itemsPerRow={itemsPerRow}
          noMore
          loadMore
          inView
        />
      </Box>
    </>
  );
}

ArenaArchive.propTypes = {
  title: PropTypes.string,
  itemsPerRow: PropTypes.number,
  allVideosText: PropTypes.string,
  search: PropTypes.bool,
  filterTagsBySelectedTags: PropTypes.bool,
  filterTagTypes: PropTypes.arrayOf(
    PropTypes.shape({
      tagTypeId: PropTypes.string,
      sort: PropTypes.string,
    })
  ),
  tagTypeId: PropTypes.string,
  tagsSort: PropTypes.string,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
  tagIds: PropTypes.arrayOf(PropTypes.string),
};
