import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ContentCarousel from "arena/components/ContentCarousel";
import HomepageTitle from "arena/components/HomepageTitle";
import HeaderMoreLink from "arena/components/HeaderMoreLink";
import { useArena } from "core/components/ArenaProvider";
import { useQuery } from "@apollo/client";
import { ArenaContentsQuery } from "arena/queries";
import qs from "qs";

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  padding: theme.spacing(0, 0, 2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 3, 2),
  },
}));

export default function ProductContents({
  title,
  type,
  productId,
  collectionId,
  sort,
  status,
  limit,
  moreUrl,
  itemsPerRow,
  xs = 6,
  sm = 6,
  md = 4,
  lg,
  xl,
}) {
  const arena = useArena();
  itemsPerRow = itemsPerRow || arena.defaultRowSize;

  const variables = {
    arenaId: arena.id,
    type,
    productId,
    limit,
    sort,
    status,
  };

  if (collectionId) {
    variables.collectionIds = [collectionId];
  }

  const { data } = useQuery(ArenaContentsQuery, {
    fetchPolicy: "cache-and-network",
    variables,
  });

  const count = data?.contents?.count || [];
  const nodes = data?.contents?.nodes || [];

  if (nodes.length === 0) return null;

  const params = {
    type,
    productId,
    sort,
    status,
  };

  const query = qs.stringify(params, {
    addQueryPrefix: true,
    skipNulls: true,
  });

  if (itemsPerRow) {
    sm = Math.max(12 / itemsPerRow, 4);
    md = Math.max(12 / itemsPerRow, 3);
    lg = 12 / itemsPerRow;
    xl = lg;
  }

  return (
    <Root>
      <HomepageTitle
        title={title}
        action={
          nodes.length < count && (
            <HeaderMoreLink url={moreUrl || `/contents${query}`} />
          )
        }
      />

      <ContentCarousel
        nodes={nodes}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        productId={productId}
        collectionId={collectionId}
      />
    </Root>
  );
}

ProductContents.propTypes = {
  itemsPerRow: PropTypes.oneOf([2, 4, 3, 6]),
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  productId: PropTypes.string,
  collectionId: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  sort: PropTypes.string,
  status: PropTypes.string,
  moreUrl: PropTypes.string,
  limit: PropTypes.number,
};
