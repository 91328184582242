import React from "react";
import { styled } from "@mui/material/styles";
import ContentBody from "arena/components/ContentBody";
import ErrorScreen from "core/components/ErrorScreen";
import useScrollToTop from "core/hooks/useScrollToTop";
import { ContentQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import { createEvent } from "core/utils/tracker";
import Grid from "@mui/material/Grid";
import { Navigate, useLocation, useParams } from "react-router-dom";
import NoMatchScreen from "arena/components/NoMatchScreen";
import ContentHelmet from "arena/components/ContentHelmet";
import { useArena } from "core/components/ArenaProvider";
import ArenaContents from "arena/components/ArenaContents";
import Box from "@mui/material/Box";

const Root = styled("div")(() => ({
  overflow: "hidden",
}));

const COLLECTION = "COLLECTION";

export default function Collection() {
  const location = useLocation();
  const { contentId } = useParams();
  const arena = useArena();

  useScrollToTop();

  const onCompleted = (data) => {
    if (data.content) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.content.title,
      });

      createEvent("page", "load");
    }
  };

  const { data, loading, error } = useQuery(ContentQuery, {
    // fetchPolicy: "cache-and-network",
    variables: { contentId },
    onCompleted,
  });

  const content = data?.content;

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!content && !loading) {
    return <NoMatchScreen />;
  }

  if (content && content.type !== COLLECTION) {
    return (
      <Navigate
        to={content.siteUrl.replace("collection", content.type.toLowerCase())}
      />
    );
  }

  return (
    <>
      <Root key={contentId}>
        <ContentHelmet content={content} arena={arena} />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ContentBody content={content} loading={loading} noComments />
          </Grid>
          {content && (
            <Grid item xs={12}>
              <Box sx={{ px: { xs: 0, sm: 3 } }}>
                <ArenaContents
                  arena={arena}
                  view="GRID"
                  sort={content.collectionContentSort || "LATEST"}
                  period={60}
                  limit={50}
                  xs={6}
                  sm={6}
                  md={4}
                  collectionId={contentId}
                  loadMore
                  inView
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Root>
    </>
  );
}
