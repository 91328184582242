import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LoadMore from "core/components/LoadMore";
import CommentsDialog from "./CommentsDialog";
import CommentsCard from "./CommentsCard";
import CommentList from "./CommentList";
import CommentMenuDrawer from "./CommentMenuDrawer";
import CommentsDialogHeader from "./CommentsDialogHeader";
import CommentsForm from "./CommentsForm";
import { useQuery, useMutation } from "@apollo/client";
import { useViewer } from "core/components/ViewerProvider";
import { DeleteCommentMutation } from "arena/mutations";
import {
  CommentCreatedSubscription,
  CommentDeletedSubscription,
} from "arena/subscriptions";
import { CommentsQuery } from "arena/queries";
import { withSnackbar } from "notistack";

function ContentComments({ contentId, enqueueSnackbar, isMobile }) {
  const { t } = useTranslation("arena");
  const viewer = useViewer();
  const [isInputInFocus, setIsInputInFocus] = useState(false);
  const [open, setOpen] = useState(false); // Открыть/закрыть диалог комментариев
  const [selectedComment, setSelectedComment] = useState(); // Комментарий на котором нажали троеточие
  const [editedComment, setEditedComment] = useState(); // Комментарий который редактируем
  const [replyComment, setReplyComment] = useState(); // Комментарий на который отвечаем

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const updateCacheAfterDelete = (cache, { data: { deleteComment } }) => {
    const { comments } = cache.readQuery({
      query: CommentsQuery,
      variables: {
        limit,
        filter: {
          contentId,
        },
      },
    });

    cache.writeQuery({
      query: CommentsQuery,
      variables: {
        limit,
        filter: {
          contentId,
        },
      },
      data: {
        comments: {
          ...comments,
          nodes: comments.nodes.filter((m) => m.id !== deleteComment.id),
        },
      },
    });
  };

  const [deleteComment] = useMutation(DeleteCommentMutation, {
    // refetchQueries: ["Comments"],
    onCompleted: () => {
      enqueueSnackbar(t("contentComments.commentDeleted"), {
        variant: "success",
      });
    },
    onError,
    update: updateCacheAfterDelete,
  });

  const limit = 10;

  const { data, loading, fetchMore, subscribeToMore } = useQuery(
    CommentsQuery,
    {
      fetchPolicy: "cache-and-network",
      skip: !process.browser,
      onError,
      variables: {
        limit,
        filter: {
          contentId,
        },
      },
    }
  );

  useEffect(() => {
    if (!process.browser) return;

    return subscribeToMore({
      document: CommentCreatedSubscription,
      variables: {
        contentId,
        userId: viewer?.id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return Object.assign({}, prev, {
          comments: {
            ...prev.comments,
            count: prev.comments.count + 1,
            nodes: [
              subscriptionData.data.commentCreated,
              ...prev.comments.nodes,
            ],
          },
        });
      },
    });
  }, [contentId, viewer?.id]);

  useEffect(() => {
    if (!process.browser) return;

    return subscribeToMore({
      document: CommentDeletedSubscription,
      variables: {
        contentId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return Object.assign({}, prev, {
          comments: {
            ...prev.comments,
            nodes: [
              ...prev.comments.nodes.filter(
                (m) => m.id !== subscriptionData.data.commentDeleted.id
              ),
            ],
          },
        });
      },
    });
  }, [contentId]);

  const comments = data?.comments?.nodes || [];
  const count = data?.comments?.count || 0;

  const onCloseComments = () => {
    setOpen(false);
  };

  const onOpenComments = () => {
    setOpen(true);
  };

  const onClickDelete = () => {
    setSelectedComment(null);
    deleteComment({
      variables: {
        input: {
          commentId: selectedComment?.id,
        },
      },
    });
  };

  const onClickDeleteAllUserComments = () => {
    setSelectedComment(null);
    deleteComment({
      variables: {
        input: {
          commentId: selectedComment?.id,
          deleteAllUserComments: true,
        },
      },
    });
  };

  const onClickBanUserPermanent = () => {
    setSelectedComment(null);
    deleteComment({
      variables: {
        input: {
          commentId: selectedComment?.id,
          userBanType: "PERMANENT",
        },
      },
    });
  };

  const onClickBanUserTemporary = () => {
    setSelectedComment(null);
    deleteComment({
      variables: {
        input: {
          commentId: selectedComment?.id,
          userBanType: "TEMPORARY",
        },
      },
    });
  };

  const onClickEdit = () => {
    setEditedComment(selectedComment);
    setSelectedComment(null);
  };

  const onClickReply = (comment) => {
    setReplyComment(comment);
    document.getElementById("comment-input")?.focus();
  };

  const onClickMore = (comment) => {
    setSelectedComment(comment);
  };

  const onCloseCommentMenu = () => {
    setSelectedComment(null);
  };

  const hasMore = comments.length < count;
  const showLoadMore = hasMore && !loading;
  const skip = comments.length;

  const onLoadMore = () => {
    fetchMore({
      variables: {
        skip,
      },
    });
  };

  const commentList = (
    <CommentList
      viewer={viewer}
      comments={comments}
      onClickReply={onClickReply}
      onClickMore={onClickMore}
      loadMore={showLoadMore && <LoadMore key={skip} onLoadMore={onLoadMore} />}
    />
  );

  const commentForm = (
    <CommentsForm
      setIsInputInFocus={setIsInputInFocus}
      contentId={contentId}
      editedComment={editedComment}
      key={editedComment?.id}
      setEditedComment={setEditedComment}
      replyComment={replyComment}
      setReplyComment={setReplyComment}
    />
  );

  const commentMenuDrawer = (
    <CommentMenuDrawer
      viewer={viewer}
      selectedComment={selectedComment}
      onClose={onCloseCommentMenu}
      onClickEdit={onClickEdit}
      onClickDelete={onClickDelete}
      onClickDeleteAllUserComments={onClickDeleteAllUserComments}
      onClickBanUserPermanent={onClickBanUserPermanent}
      onClickBanUserTemporary={onClickBanUserTemporary}
    />
  );

  const commentDialogHeader = (
    <CommentsDialogHeader onClose={onCloseComments} count={count} />
  );

  if (!isMobile) {
    return (
      <>
        {commentForm}
        {commentList}
        {commentMenuDrawer}
      </>
    );
  }

  return (
    <>
      <CommentsCard
        comments={comments}
        count={count}
        onClick={onOpenComments}
      />

      <CommentsDialog
        isInputInFocus={isInputInFocus}
        contentId={contentId}
        open={open}
        onClose={onCloseComments}
        onOpen={onOpenComments}
        viewer={viewer}
        editedComment={editedComment}
        setEditedComment={setEditedComment}
        replyComment={replyComment}
        setReplyComment={setReplyComment}
        count={count}
      >
        {commentDialogHeader}
        {commentForm}
        {commentList}
        {commentMenuDrawer}
      </CommentsDialog>
    </>
  );
}

ContentComments.propTypes = {
  isMobile: PropTypes.bool,
  contentId: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(ContentComments);
