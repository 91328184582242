import formatCurrency from "core/utils/currency";

const DONATION = "DONATION";
const SUBSCRIPTION = "SUBSCRIPTION";

/**
 * Получить текст цены для кнопки в карточке продукта
 *
 * @param {Object} product - Продукт
 * @param {Object} price - Цена
 * @param {Function} t - Переводы
 */
export default function getPriceButtonText(product, price, t) {
  if (product.type === DONATION) {
    return t("productCarouselItem.donate");
  }

  if (product.viewerPurchase) {
    return t("productCarouselItem.open");
  }

  const value =
    price.currency === "XTR"
      ? price.value
      : formatCurrency(price.value, price.currency);

  if (product.type === SUBSCRIPTION) {
    if (price.trialPeriod) {
      if (price.value) {
        return t("productCarouselItem.trialPrice", {
          value,
          count: price.trialPeriod,
        });
      } else {
        return t("productCarouselItem.freeTrial", { count: price.trialPeriod });
      }
    }
  }

  return value;
}
