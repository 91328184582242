import React from "react";
import PropTypes from "prop-types";
import ContentPlayer from "arena/components/ContentPlayer";
import ContentBody from "arena/components/ContentBody";
import NextPlaylistContentButton from "arena/components/NextPlaylistContentButton";
import ErrorScreen from "core/components/ErrorScreen";
import { ContentQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import ReactGA from "react-ga4";
import { createEvent } from "core/utils/tracker";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import NoMatchScreen from "arena/components/NoMatchScreen";
import ContentHelmet from "arena/components/ContentHelmet";
import { useArena } from "core/components/ArenaProvider";
import Box from "@mui/material/Box";
import { CloseRouteDialogButton } from "arena/components/RouteDialog";
import useIsMobile from "arena/hooks/useIsMobile";
import RelatedContent from "arena/components/RelatedContent";
import ContentLayout from "arena/components/ContentLayout";
import ContentComments from "arena/components/ContentComments";
import { useTranslation } from "react-i18next";

const VIDEO = "VIDEO";
const VIDEO_ON_SITE = "VIDEO_ON_SITE";

export default function Video({ params: { contentId } }) {
  const { t } = useTranslation("arena");
  const isMobile = useIsMobile();
  const arena = useArena();
  const location = useLocation();
  // const { contentId } = useParams();
  const { collectionId, productId, start, finish } = useQueryParams();

  const onCompleted = (data) => {
    if (data.content) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.content.title,
      });

      createEvent("page", "load");
    }
  };

  const { data, previousData, loading, error, refetch } = useQuery(
    ContentQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        contentId,
      },
      onCompleted,
    }
  );

  const content = data?.content || previousData?.content;

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!content && !loading) {
    return <NoMatchScreen />;
  }

  if (content && content.type !== VIDEO) {
    return (
      <Navigate
        to={content.siteUrl.replace("video", content.type.toLowerCase())}
      />
    );
  }

  const showNextContentButton = content?.type === VIDEO;
  // Boolean(productId) || Boolean(collectionId) || ;

  const tabsSchema = {
    RELATED_VIDEO: {
      label: t("video.tabsSchema.relatedVideo"), //Related Video",
      component: (
        <RelatedContent
          type="VIDEO"
          limit={6}
          selectedContentId={contentId}
          relatedContentId={contentId}
          productId={productId}
          collectionId={collectionId}
        />
      ),
      enabled: true,
    },
    COMMENTS: {
      label: t("video.tabsSchema.comments"), //"Comments",
      component: <ContentComments contentId={contentId} />,
      enabled: content?.isCommentsEnabled,
    },
  };

  return (
    <Box data-testid="Video">
      <ContentHelmet content={content} arena={arena} />

      <ContentLayout tabsSchema={tabsSchema}>
        <ContentPlayer
          sponsorPlayerLogoPlace={VIDEO_ON_SITE}
          sponsorPlayerButtonPlace={VIDEO_ON_SITE}
          sponsorVideoPlace={VIDEO_ON_SITE}
          sponsorInStreamBannerPlace={VIDEO_ON_SITE}
          sponsorCornerBannerPlace={VIDEO_ON_SITE}
          hideTitle
          autoPlay
          content={content}
          loading={loading}
          start={start}
          finish={finish}
          refetch={refetch}
          nextPlaylistContentButton={({
            videoState: { duration, currentTime, isEnded },
            visible,
          }) => {
            if (!duration) return null;
            if (!currentTime && currentTime !== 0) return null;
            if (!showNextContentButton) return null;

            const timeLeft = duration - currentTime;

            return (
              <NextPlaylistContentButton
                visible={visible}
                timeLeft={timeLeft}
                contentId={contentId}
                productId={productId}
                collectionId={collectionId}
                isEnded={isEnded}
              />
            );
          }}
          closeRouteDialogButton={<CloseRouteDialogButton />}
        />
        <ContentBody content={content} loading={loading} />

        {content?.isCommentsEnabled && isMobile && (
          <ContentComments contentId={contentId} isMobile />
        )}
      </ContentLayout>

      {isMobile && (
        <RelatedContent
          type="VIDEO"
          limit={6}
          selectedContentId={contentId}
          relatedContentId={contentId}
          productId={productId}
          collectionId={collectionId}
          isMobile
        />
      )}
    </Box>
  );
}

Video.propTypes = {
  params: PropTypes.shape({
    contentId: PropTypes.string.isRequired,
  }),
};

function useQueryParams() {
  const [searchParams] = useSearchParams();

  const productId = searchParams.get("productId");
  const collectionId = searchParams.get("collectionId");

  const start = searchParams.has("start")
    ? parseInt(searchParams.get("start"), 10)
    : null;

  const finish = searchParams.has("finish")
    ? parseInt(searchParams.get("finish"), 10)
    : null;

  return { collectionId, productId, start, finish };
}
