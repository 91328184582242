import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Link from "arena/components/Link";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { getAvatarSrcSet } from "core/utils/getSrcSet";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { truncate } from "core/utils/string";

const MAX_TAGS = 3;

export default function TagList({ tags }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const [collapsed, setCollapsed] = React.useState(tags.length > MAX_TAGS);

  const onClickMore = () => {
    setCollapsed(false);
  };

  return (
    <Box
      sx={{
        // overflowX: "auto",
        // width: "100%",
        // scrollbarWidth: "none",
        // "&::-webkit-scrollbar": {
        //   display: "none",
        // },
        pl: {
          xs: 2,
          sm: 3,
        },
        pr: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      <Grid
        container
        spacing={1}
        // wrap="nowrap"
      >
        {tags.map((tag, index) => {
          if (!sm) {
            if (index >= MAX_TAGS) {
              if (collapsed) {
                return null;
              }
            }
          }
          return (
            <Grid item key={tag.id}>
              <Chip
                // color="primary"
                label={truncate(tag.title, 30)}
                clickable
                component={Link}
                to={tag.url}
                size={sm ? "large" : "small"}
                sx={{ bgcolor: "background.paper" }}
                avatar={
                  tag.image && (
                    <Avatar
                      src={tag.image?.url}
                      srcSet={getAvatarSrcSet(tag?.image?.thumbnails)}
                    />
                  )
                }
              />
            </Grid>
          );
        })}
        {collapsed && !sm && (
          <Grid item key="more">
            <Chip
              // color="primary"
              label={`+${tags.length - MAX_TAGS}`}
              size={sm ? "large" : "small"}
              sx={{ bgcolor: "background.paper" }}
              onClick={onClickMore}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

TagList.propTypes = {
  link: PropTypes.func,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    })
  ),
};
