import React, { useEffect } from "react";
import { ArenaDraftQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import LoadingScreen from "core/components/LoadingScreen";
import ErrorScreen from "core/components/ErrorScreen";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { isTelegramMiniApp, launchParams } from "core/utils/telegram";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import { openTelegramLink } from "@telegram-apps/sdk";

export default function ArenaNotFound() {
  const { data, loading, error } = useQuery(ArenaDraftQuery);
  const { i18n, t } = useTranslation("arena");

  useEffect(() => {
    if (launchParams?.initData?.user?.languageCode) {
      i18n.changeLanguage(launchParams.initData.user.languageCode);
    }
  }, [i18n]);

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorScreen error={error} />;

  if (data?.arenaDraft) {
    const arenaDraft = data.arenaDraft;

    const onClick = () => {
      if (openTelegramLink.isAvailable()) {
        return openTelegramLink(arenaDraft.botUrl);
      }

      window.open(arenaDraft.botUrl);
    };

    return (
      <ErrorScreen
        icon={PhonelinkSetupIcon}
        title={
          arenaDraft.isTelegramArena
            ? t("arena.isDraft.telegram")
            : t("arena.isDraft.web")
        }
        text={
          arenaDraft.isTelegramArena
            ? t("arena.isDraft.telegramDescription", { url: arenaDraft.url })
            : t("arena.isDraft.webDescription")
        }
        button={
          arenaDraft.isTelegramArena ? (
            <Button variant="contained" color="primary" onClick={onClick}>
              {t("arena.isDraft.openBot")}
            </Button>
          ) : undefined
        }
      />
    );
  }

  return (
    <ErrorScreen
      title={
        isTelegramMiniApp
          ? t("arena.notFound.telegram")
          : t("arena.notFound.web")
      }
      code={404}
    />
  );
}
