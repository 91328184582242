import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const Root = styled("div")({
  maxWidth: 600,
});

export default function Inner({ children }) {
  return <Root test-id="ArenaCustomCoverInner">{children}</Root>;
}

Inner.propTypes = {
  children: PropTypes.node,
};
