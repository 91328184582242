import React from "react";
import PropTypes from "prop-types";
import Grid from "./Grid";
import Carousel from "./Carousel";
import ReactGA from "react-ga4";
import HeaderMoreLink from "arena/components/HeaderMoreLink";
import HomepageTitle from "arena/components/HomepageTitle";
import { useQuery } from "@apollo/client";
import LoadMore from "core/components/LoadMore";
import { useArena } from "core/components/ArenaProvider";
import { DigitalAssetsQuery } from "arena/queries";

const components = {
  CAROUSEL: Carousel,
  GRID: Grid,
};

/**
 * Список в стиле полки контента
 */
export default function ArenaDigitalAssets({
  title,
  view,
  variant,
  limit = 12,
  tagIds,
  productIds,
  rightholderIds,
  loadMore,
  itemsPerRow,
}) {
  const arena = useArena();
  itemsPerRow = itemsPerRow || arena.defaultRowSize;

  const variables = {
    rowsPerPage: limit,
    filter: {
      tagIds,
      productIds,
    },
  };

  if (rightholderIds?.length) {
    variables.filter.rightholderIds = rightholderIds;
  }

  const { data, loading, fetchMore } = useQuery(DigitalAssetsQuery, {
    variables,
  });

  const count = data?.digitalAssets?.count || 0;
  const nodes = data?.digitalAssets?.nodes || [];

  if (!count) return null;

  let moreLink = null;

  if (!loadMore) {
    if (count > limit) {
      const searchParams = new URLSearchParams();
      if (tagIds) {
        tagIds.forEach((tagId) => {
          searchParams.append("tagId", tagId);
        });
      }
      moreLink = `/digitalAssets?${searchParams.toString()}`;
    }
  }

  const hasMore = nodes.length < count;
  const showLoadMore = loadMore && view === "GRID" && hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / limit);

  const onLoadMore = () => {
    ReactGA.event({
      category: "User",
      action: "Load more digitalAssets",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  const Component = components[view];

  const xs = 6;
  const sm = Math.max(12 / itemsPerRow, 4);
  const md = Math.max(12 / itemsPerRow, 3);
  const lg = 12 / itemsPerRow;
  const xl = lg;

  return (
    <>
      {title && (
        <HomepageTitle
          title={title}
          action={moreLink && <HeaderMoreLink url={moreLink} />}
        />
      )}

      <Component
        items={nodes}
        variant={variant}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
      />

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </>
  );
}

ArenaDigitalAssets.defaultProps = {
  // itemsPerRow: 3,
  view: "CAROUSEL",
  variant: "TITLE_INSIDE",
};

ArenaDigitalAssets.propTypes = {
  title: PropTypes.string,
  limit: PropTypes.number,
  itemsPerRow: PropTypes.number,
  tagIds: PropTypes.arrayOf(PropTypes.string),
  productIds: PropTypes.arrayOf(PropTypes.string),
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  view: PropTypes.oneOf(["CAROUSEL", "GRID"]).isRequired,
  variant: PropTypes.oneOf(["TITLE_INSIDE", "TITLE_OUTSIDE"]),
  loadMore: PropTypes.bool,
};
