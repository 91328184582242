import React, { useState } from "react";
import PropTypes from "prop-types";
import HomepageTitle from "arena/components/HomepageTitle";
import ProductList from "arena/components/ProductList";
import ProductCarousel from "arena/components/ProductCarousel";
import ProductPromoVideoDialog from "arena/components/ProductPromoVideoDialog";
import ErrorScreen from "core/components/ErrorScreen";
import { ProductsQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import ReactGA from "react-ga4";

export default function ArenaProducts({
  arena,
  limit = 12,
  title,
  loadMore,
  type,
  rightholderIds,
  view,
}) {
  const [product, setProduct] = useState();

  const variables = {
    arenaId: arena.id,
    rowsPerPage: limit,
    type,
  };

  if (rightholderIds?.length) {
    variables.rightholderIds = rightholderIds;
  }

  const { data, loading, error, fetchMore } = useQuery(ProductsQuery, {
    fetchPolicy: "cache-and-network",
    variables,
  });

  if (error) return <ErrorScreen error={error} />;

  const nodes = data?.products?.nodes || [];
  const count = data?.products?.count || 0;

  if (loading && !data) {
    return null;
  }

  if (!nodes || nodes.length === 0) {
    return null;
  }

  const onClosePromoVideoDialog = () => setProduct();
  const onClickPromoVideoButton = (product) => setProduct(product);

  const hasMore = nodes.length < count;
  const showLoadMore = loadMore && hasMore && !loading;
  const nextPage = Math.ceil(nodes.length / limit);

  const onLoadMore = () => {
    ReactGA.event({
      category: "User",
      action: "Load more products",
      label: nextPage + " page",
    });

    fetchMore({
      variables: {
        page: nextPage,
      },
    });
  };

  const components = {
    CAROUSEL: ProductCarousel,
    GRID: ProductList,
  };

  const List = components[view] || ProductList;

  return (
    <>
      <List
        title={title && <HomepageTitle title={title} />}
        rowsPerPage={limit}
        loadMore={loadMore}
        loading={loading}
        nodes={nodes}
        count={count}
        onLoadMore={onLoadMore}
        showLoadMore={showLoadMore}
        nextPage={nextPage}
        onClickPromoVideoButton={onClickPromoVideoButton}
      />
      {product && (
        <ProductPromoVideoDialog
          onClose={onClosePromoVideoDialog}
          open={!!product}
          product={product}
        />
      )}
    </>
  );
}

ArenaProducts.propTypes = {
  view: PropTypes.oneOf(["CAROUSEL", "GRID"]),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  type: PropTypes.string,
  limit: PropTypes.number,
  title: PropTypes.string,
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
  loadMore: PropTypes.bool,
};
