import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { ProductQuery } from "arena/queries";
// import { useLocation, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";

// import { useTranslation } from "react-i18next";
import { useViewer } from "core/components/ViewerProvider";
import NoMatchScreen from "arena/components/NoMatchScreen";
import ProductHelmet from "arena/components/ProductHelmet";
import ReactGA from "react-ga4";
import ErrorScreen from "core/components/ErrorScreen";
import ProductPayment from "arena/components/ProductPayment";
import ProductPurchase from "arena/components/ProductPurchase";
import ProductDialog from "arena/components/ProductDialog";
import ProductHelpDialog from "arena/components/ProductHelpDialog";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import useProductDialog from "arena/hooks/useProductDialog";

import Grid from "@mui/material/Grid";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  height: "100%",
  [theme.breakpoints.up("sm")]: {
    padding: 0,
  },
}));

export default function ProductPaymentCard() {
  const { productId } = useParams();
  const [promocodeIds, setPromocodeIds] = useState([]);
  const { state, dispatch } = useProductDialog();
  const [helpOpen, setHelpOpen] = useState(false);

  // const { t } = useTranslation("arena");
  const viewer = useViewer();
  // const location = useLocation();
  // const [searchParams] = useSearchParams();

  const onClickPriceButton = (event, price) => {
    event.preventDefault();

    ReactGA.event({
      category: "Product",
      action: "Click to create payment button",
      label: product.title,
    });

    const { providers } = price;
    const [provider] = providers;

    dispatch({
      type: "create_payment",
      provider,
      providers,
      priceId: price?.id,
      amount: price?.amount,
      recurrent: price?.recurrent,
      productType: product.type,
    });
  };

  const onClickHelpButton = () => {
    setHelpOpen(true);
    ReactGA.event({
      category: "Product",
      action: "Click help button",
      label: product.title,
    });
  };

  const onCompleted = (data) => {
    if (data.product && process.browser) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.product.title,
      });
    }
  };

  const onCloseHelpDialog = () => {
    setHelpOpen(false);
    ReactGA.event({
      category: "Product",
      action: "Close help",
      label: product.title,
    });
  };

  const { data, previousData, loading, error, refetch } = useQuery(
    ProductQuery,
    {
      fetchPolicy: "network-only",
      // nextFetchPolicy: "cache-and-network",
      variables: {
        id: productId,
        miniAppWebPayment: true,
        promocodeIds,
      },
      onCompleted,
    }
  );

  const product = data?.product || previousData?.product;

  if (loading && !product) {
    return null;
  }

  if (!product && !loading) {
    return <NoMatchScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }
  const viewerPurchase = product?.viewerPurchase;

  const showProductPayment = !viewerPurchase;
  const showProductPurchase = !showProductPayment;

  return (
    <>
      {product && <ProductHelmet product={product} />}
      <StyledBox>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "100%" }}
        >
          <Grid item xs={12} sm={6} md={6} lg={4}>
            {showProductPayment && (
              <ProductPayment
                promocodeIds={promocodeIds}
                setPromocodeIds={setPromocodeIds}
                viewer={viewer}
                product={product}
                onClickHelpButton={onClickHelpButton}
                onClickPriceButton={onClickPriceButton}
                refetch={refetch}
                loading={loading}
                showTitle
              />
            )}

            {showProductPurchase && (
              <ProductPurchase showShareUrl product={product} />
            )}
          </Grid>
        </Grid>
      </StyledBox>

      {product && (
        <ProductHelpDialog
          onClose={onCloseHelpDialog}
          open={helpOpen}
          product={product}
        />
      )}
      <ProductDialog
        setPromocodeIds={setPromocodeIds}
        product={product}
        state={state}
        dispatch={dispatch}
        refetch={refetch}
      />
    </>
  );
}
