import React from "react";
import Typography from "@mui/material/Typography";

export default function ContentTitle(props) {
  return (
    <Typography
      variant="body1"
      color="textPrimary"
      component="h1"
      align="left"
      display="inline-block"
      sx={{
        fontWeight: 800,
        lineHeight: 1.1,
        fontSize: {
          xs: "1.1rem",
          sm: "1.2rem",
          md: "1.3rem",
          lg: "2rem",
        },
        pl: {
          xs: 2,
          sm: 3,
        },
        pr: {
          xs: 2,
          sm: 3,
        },
      }}
      {...props}
    />
  );
}
