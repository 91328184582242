import { ApolloClient } from "@apollo/client";
import { from, split, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
// import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";
import fetch from "node-fetch";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import getReferrer from "core/utils/getReferrer";
import apm from "./apm";
import LocalStorage from "./localstorage";
import createApolloCache from "core/utils/createApolloCache";
import { isTelegramMiniApp, launchParams } from "core/utils/telegram";

// import i18n from "core/utils/i18n";

const ls = new LocalStorage(`apollo`);

let viewerId = ls.getItem("viewerId");

const httpLink = createUploadLink({
  uri: "/site/graphql",
  fetch,
  credentials: "same-origin",
  // useGETForQueries: true,
});

const auth = new ApolloLink((operation, forward) => {
  const url = getReferrer();

  const headers = {
    "X-Referer": url,
    "Apollo-Require-Preflight": "true",
  };

  if (launchParams) {
    headers["X-MiniApp-Authorization"] = launchParams.initDataRaw;
  }

  if (viewerId) {
    headers["x-viewerId"] = viewerId;
  }

  operation.setContext({ headers });

  const result = forward(operation);

  if (!result.map) return result;

  return result.map((response) => {
    const context = operation.getContext();
    if (viewerId !== context.response.headers.get("x-viewerId")) {
      viewerId = context.response.headers.get("x-viewerId");
      ls.setItem("viewerId", viewerId);
    }
    return response;
  });
});

// const retryLink = new RetryLink();

let errorsCount = 0;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map((error) => {
      console.log(
        `[GraphQL error]: Code: ${
          error.extensions?.code || "Unknown"
        } Message: ${error.message}`
      );

      apm.captureError(error);
    });

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    apm.captureError(networkError);
  }

  errorsCount++;

  if (typeof window !== "undefined") {
    if (errorsCount > 100) {
      console.log("force reload");
      window.location.reload(true);
    }
  }
});

const apolloCache = createApolloCache();
if (typeof window !== "undefined") {
  apolloCache.restore(window.__APOLLO_STATE__);
}

export const wsLink = process.browser
  ? new WebSocketLink({
      uri:
        isTelegramMiniApp &&
        process.env.RAZZLE_GRAPHQL_TELEGRAM_SUBSCRIPTION_URL
          ? process.env.RAZZLE_GRAPHQL_TELEGRAM_SUBSCRIPTION_URL
          : process.env.RAZZLE_GRAPHQL_SUBSCRIPTION_URL,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: () => {
          const params = {};
          const referer = getReferrer();

          const token = ls.getItem("token");

          if (token) {
            params.Authorization = token;
          }

          if (viewerId) {
            params.viewerId = viewerId;
          }

          params.host = window.location.hostname;
          params.referer = referer;

          return params;
        },
      },
    })
  : null;

/**
 * Для снижения нагрузки на апи
 * часть запросов будем отсылать по вебсокетам
 * если установлено соединение
 * запросы wsMutations и wsQuery
 *
 * Надо помнить что в эти треки не прокидывается реферер
 */
const wsMutations = [
  "TrackWidget",
  "TrackAd",
  "CreateEvent",
  "UpdateViewData",
  "CreateSponsorImpression",
  "CreateSponsorClick",
  // Здесь нету CreateView потому что у нее куча резолверов с даталоадерами
];

const wsQuery = ["NotificationCount"];

const link = process.browser
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);

        if (!definition.kind === "OperationDefinition") {
          return false;
        }

        if (definition.operation === "subscription") {
          return true;
        }

        // if (wsLink?.subscriptionClient?.client?.readyState !== 1) {
        //   return false;
        // }

        if (definition.operation === "mutation") {
          return wsMutations.includes(definition.name.value);
        }

        if (definition.operation === "query") {
          return wsQuery.includes(definition.name.value);
        }

        return false;
      },
      wsLink,
      httpLink
    )
  : httpLink;

export const client = new ApolloClient({
  ssrForceFetchDelay: 100,
  ssrMode: typeof window === "undefined",
  link: from([
    // retryLink,
    errorLink,
    auth,
    link,
  ]),
  cache: apolloCache,
  assumeImmutableResults: true,
  connectToDevTools: true,
});
