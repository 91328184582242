import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import { useViewer } from "core/components/ViewerProvider";
import TextField from "@mui/material/TextField";
import { CreateCommentMutation, UpdateCommentMutation } from "arena/mutations";
import { useMutation } from "@apollo/client";
import { withSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import Zoom from "@mui/material/Zoom";
import ReplyCommentPreview from "./ReplyCommentPreview";
import { useTranslation } from "react-i18next";

function CommentsForm({
  enqueueSnackbar,
  contentId,
  editedComment,
  setEditedComment,
  replyComment,
  setReplyComment,
  setIsInputInFocus,
}) {
  const { t } = useTranslation("arena");
  const viewer = useViewer();
  const [message, setMessage] = React.useState(editedComment?.message || "");

  const [createComment, { loading: createLoading }] = useMutation(
    CreateCommentMutation,
    {
      refetchQueries: ["Comments"],
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      onCompleted: () => {
        enqueueSnackbar(t("commentsDialogFooter.commentsCreated"), {
          variant: "success",
        });
      },
    }
  );

  const [updateComment, { loading: updateLoading }] = useMutation(
    UpdateCommentMutation,
    {
      refetchQueries: ["Comments"],
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      onCompleted: () => {
        enqueueSnackbar(t("commentsDialogFooter.commentsUpdated"), {
          variant: "success",
        });
      },
    }
  );

  const avatarUrl = viewer?.photo?.url || viewer?.photoUrl;

  const onChange = (event) => {
    setMessage(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (editedComment) {
      return updateComment({
        variables: {
          id: editedComment.id,
          input: {
            message,
          },
        },
      }).then(() => {
        setMessage("");
        setEditedComment(null);
        event.target.reset();
        return;
      });
    }

    return createComment({
      variables: {
        input: {
          contentId,
          message,
          replyToId: replyComment?.id,
        },
      },
    }).then(() => {
      setMessage("");
      setReplyComment(null);
      event.target.reset();
      document.getElementById("comment-input")?.focus();
      return;
    });
  };

  const onClickCloseReply = () => {
    setReplyComment(null);
  };

  const loading = createLoading || updateLoading;

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "background.body",
        boxShadow: "none",
      }}
      color="default"
    >
      {replyComment && (
        <ReplyCommentPreview
          comment={replyComment}
          onClickClose={onClickCloseReply}
        />
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          px: { xs: 1, sm: 2 },
          pt: { xs: 1, sm: 2 },
          pb: { xs: 2, sm: 4 },
        }}
        component="form"
        onSubmit={onSubmit}
      >
        <Avatar
          sx={{
            width: {
              xs: 34,
              sm: 40,
            },
            height: {
              xs: 34,
              sm: 40,
            },
            mr: { xs: 1, sm: 2 },
          }}
          src={avatarUrl}
        />

        <TextField
          fullWidth
          placeholder={t("commentsDialogFooter.placeholder")}
          multiline
          maxRows={5}
          onChange={onChange}
          onFocus={() => setIsInputInFocus(true)}
          onBlur={(event) => setIsInputInFocus(event.target.value.length !== 0)}
          autoFocus
          variant="standard"
          disabled={loading}
          //   disableUnderline
          name="message"
          value={message}
          id="comment-input"
          sx={{
            "& .MuiInputBase-root": {
              fontSize: "0.875rem",
            },
          }}
          onClick={(event) => {
            if (!viewer) {
              event.preventDefault();
              return document.querySelector(".js-sign-in")?.click();
            }
          }}
        />

        <Zoom in={message.length > 0}>
          <IconButton
            color="inherit"
            size="small"
            disabled={loading}
            type="submit"
            name="submit"
          >
            <SendIcon />
          </IconButton>
        </Zoom>
      </Box>
    </AppBar>
  );
}

CommentsForm.propTypes = {
  contentId: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  editedComment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }),
  setEditedComment: PropTypes.func.isRequired,
  replyComment: PropTypes.object,
  setReplyComment: PropTypes.func.isRequired,
  setIsInputInFocus: PropTypes.func.isRequired,
};

export default withSnackbar(CommentsForm);
