import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tabs from "./Tabs";

const SIDEBAR_WIDTH = 400;

export default function ContentDesktopSidebar({ tabsSchema, tabs }) {
  const [tab, setTab] = useState(tabs[0].value);

  const onChangeTab = useCallback((event, tab) => {
    event.preventDefault();
    setTab(tab);
  }, []);

  return (
    <Box
      sx={{
        width: SIDEBAR_WIDTH,
        flexShrink: 0,
        position: "relative",
        display: { xs: "none", md: "block" },
      }}
    >
      <Box
        sx={{
          width: SIDEBAR_WIDTH,
          position: "fixed",
          top: 64,
          right: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.header",
        }}
      >
        <Tabs tab={tab} onChangeTab={onChangeTab} tabs={tabs} />
        <Divider />
        {tabsSchema[tab].component}
      </Box>
    </Box>
  );
}

ContentDesktopSidebar.propTypes = {
  tabsSchema: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};
