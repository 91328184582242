import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { useQuery } from "@apollo/client";
import { NextPlaylistContentQuery } from "arena/queries";
import { useNavigate } from "react-router-dom";
import Link from "arena/components/Link";
import contentUrl from "core/utils/contentUrl";
import { useTranslation } from "react-i18next";

const TIMEOUT = 10; // 10 секунд. За сколько секунд до конца видоса показывать кнопку перехода к след видео

const Label = styled("span")({
  zIndex: 0,
});

const StyledLinearProgress = styled(LinearProgress)({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  height: "auto",
  zIndex: 0,
});

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !["visible"].includes(prop),
})(({ theme, visible }) => ({
  zIndex: 2,
  overflow: "hidden",
  position: "absolute",
  bottom: visible ? 66 : theme.spacing(1),
  right: theme.spacing(1),
  ["@media (min-width: 375px)"]: {
    bottom: visible ? 76 : theme.spacing(2),
    right: theme.spacing(2),
  },
  [theme.breakpoints.up("md")]: {
    bottom: visible ? 76 : theme.spacing(3),
    right: theme.spacing(3),
  },
}));

export default function NextPlaylistContentButton({
  timeLeft,
  contentId,
  productId,
  collectionId,
  visible,
  isEnded,
}) {
  const { t } = useTranslation("core");
  const navigate = useNavigate();

  const skip = timeLeft > TIMEOUT; // показываем кнопку только если до конца ролика осталось < 10 сек

  const { data } = useQuery(NextPlaylistContentQuery, {
    skip,
    variables: {
      productId,
      contentId,
      collectionId,
    },
  });

  useEffect(() => {
    if (!isEnded) return;
    if (!data?.nextPlaylistContent) return;

    const to = contentUrl(data.nextPlaylistContent.siteUrl, {
      productId,
      collectionId,
    });

    navigate(to);
  }, [isEnded, skip]);

  if (!data?.nextPlaylistContent) return null;

  const to = contentUrl(data.nextPlaylistContent.siteUrl, {
    productId,
    collectionId,
  });

  const progress = 100 - timeLeft * TIMEOUT;

  return (
    <StyledButton
      variant="contained"
      visible={visible}
      component={Link}
      to={to}
    >
      <StyledLinearProgress variant="determinate" value={progress} />
      <Label>{t("next")}</Label>
    </StyledButton>
  );
}

NextPlaylistContentButton.propTypes = {
  timeLeft: PropTypes.number.isRequired,
  contentId: PropTypes.string.isRequired,
  productId: PropTypes.string,
  collectionId: PropTypes.string,
  visible: PropTypes.bool,
  isEnded: PropTypes.bool,
};
