import React from "react";
import PropTypes from "prop-types";
import PlayerPreview from "core/components/PlayerPreview";
import PlayerFooter from "core/components/PlayerFooter";
import { useCast } from "core/components/CastProvider";
import Typography from "@mui/material/Typography";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLiveSeekBar } from "core/components/LiveSeekBar";

const StatusRoot = styled("div")(({ theme }) => ({
  display: "flex",
  width: "auto",
  position: "absolute",
  minHeight: 56,
  alignItems: "center",
  left: theme.spacing(1),
  right: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    left: theme.spacing(2),
    right: theme.spacing(2),
    top: theme.spacing(3),
  },
  [theme.breakpoints.up("md")]: {
    left: theme.spacing(3),
    right: theme.spacing(3),
    top: theme.spacing(3),
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export default function CastLiveScreen({
  content,
  hideShare,
  hideLogo,
  hlsStreamPlaylist,
  setOffset,
  offset,
  closeRouteDialogButton,
}) {
  const { t } = useTranslation("core");
  const {
    playerState,
    duration,
    playOrPause,
    device,
    isMuted,
    volume,
    muteOrUnmute,
    setVolumeLevel,
    startCast,
  } = useCast();

  /**
   * инициализируем LiveSeekBar для отлова изменения offset
   * запускаем каст с новым offset и прокидываем значение offset наверх
   */
  const castLiveSeekBar = useLiveSeekBar(content.streamData.startAt, {
    startOffset: offset,
    onSetOffset: (newOffset) => {
      setOffset(newOffset);
      startCast({
        hlsUrl: hlsStreamPlaylist,
        offset: newOffset,
        contentId: content.id,
        title: content.title,
        subtitle: content.rightholder.name,
        preview: content.preview,
        live: true,
      });
    },
  });

  let status;

  if (playerState === "PLAYING") {
    status = "playing";
  } else if (playerState === "PAUSED") {
    status = "paused";
  } else if (playerState === "BUFFERING") {
    status = "buffering";
  }

  const onClickPlay = () => {
    playOrPause();
  };
  const onClickPause = () => {
    playOrPause();
  };

  const videoActions = {
    toggleMute: () => {
      muteOrUnmute();
    },
    setVolume: (value) => {
      setVolumeLevel(value);
    },
  };

  const videoState = {
    status,
    duration,
    cast: true,
    live: true,
    muted: isMuted,
    volume,
  };

  return (
    <PlayerPreview preview={content.preview}>
      {device && (
        <StatusRoot>
          <CastConnectedIcon />
          <StyledTypography variant="h6">
            {t("castVideoScreen.youCastOnDevice", {
              name: device.friendlyName,
            })}
          </StyledTypography>
        </StatusRoot>
      )}
      <PlayerFooter
        content={content}
        onClickPlay={onClickPlay}
        onClickPause={onClickPause}
        position="absolute"
        visible
        hideShare={hideShare}
        hideLogo={hideLogo}
        fullScreen={false}
        videoState={videoState}
        videoActions={videoActions}
        liveSeekBar={castLiveSeekBar}
      />
      {closeRouteDialogButton}
    </PlayerPreview>
  );
}

CastLiveScreen.propTypes = {
  closeRouteDialogButton: PropTypes.node,
  fullscreenTarget: PropTypes.string,
  hideShare: PropTypes.bool,
  hideLogo: PropTypes.bool,
  autoPlay: PropTypes.bool,
  hideTitle: PropTypes.bool,
  hlsStreamPlaylist: PropTypes.string,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    preview: PropTypes.string,
    hideLogo: PropTypes.bool,
    rightholder: PropTypes.shape({
      isAdvEnabled: PropTypes.bool,
      name: PropTypes.string,
    }),
    streamData: PropTypes.shape({
      startAt: PropTypes.string,
    }),
  }).isRequired,
  liveSeekBar: PropTypes.object,
  setOffset: PropTypes.func,
  offset: PropTypes.number,
};
