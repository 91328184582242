import React from "react";
import PropTypes from "prop-types";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ErrorScreen from "core/components/ErrorScreen";
import PlayerPreview from "core/components/PlayerPreview";
import { useTranslation } from "react-i18next";

export default function StreamOfflineScreen({
  content,
  closeRouteDialogButton,
}) {
  const { t } = useTranslation("core");

  return (
    <PlayerPreview preview={content.preview} overlay>
      <ErrorScreen
        icon={LiveTvIcon}
        title={t("streamIsOffline.title")}
        text={t("streamIsOffline.text")}
      />
      {closeRouteDialogButton}
    </PlayerPreview>
  );
}

StreamOfflineScreen.propTypes = {
  closeRouteDialogButton: PropTypes.node,
  content: PropTypes.shape({
    preview: PropTypes.string,
    startAt: PropTypes.string,
  }).isRequired,
};
