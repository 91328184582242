import { InMemoryCache } from "@apollo/client";

/**
 * Функция мерджа списков в кэше для запросов у которых пагинация реализована через skip, limit
 */
const connectionLimitMerge = (existing, incoming, { args: { skip = 0 } }) => {
  const { count, __typename } = incoming;

  let nodes = [];

  if (existing) {
    if (skip > 0) {
      nodes = nodes.concat(existing.nodes);
    }
  }

  nodes = nodes.concat(incoming.nodes);

  return {
    __typename,
    count,
    nodes,
  };
};

/**
 * Функция мерджа списков в кэше для запросов у которых пагинация реализована через page, rowsPerPage
 */
const connectionPageMerge = (existing, incoming, { args: { page = 0 } }) => {
  const { count, __typename } = incoming;

  let nodes = [];

  if (existing) {
    if (page > 0) {
      nodes = nodes.concat(existing.nodes);
    }
  }

  nodes = nodes.concat(incoming.nodes);

  return {
    __typename,
    count,
    nodes,
  };
};

export default function createApolloCache() {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          contents: {
            keyArgs: [
              "productId",
              "productIds",
              "collectionId",
              "collectionIds",
              "status",
              "type",
              "sort",
              "period",
              "search",
              "relatedContentId",
              "tagIds",
              "anyTagIds",
              "limit",
              "rightholderIds",
            ],
            merge: connectionLimitMerge,
          },
          comments: {
            keyArgs: ["filter", "rowsPerPage"],
            merge: connectionLimitMerge,
          },
          posts: {
            keyArgs: ["tagIds", "rowsPerPage", "rightholderIds"],
            merge: connectionPageMerge,
          },
          products: {
            keyArgs: [
              "rightholderSlug",
              "type",
              "types",
              "rowsPerPage",
              "rightholderIds",
            ],
            merge: connectionPageMerge,
          },
          productPurchases: {
            keyArgs: ["rowsPerPage"],
            merge: connectionPageMerge,
          },
          bans: {
            keyArgs: ["rowsPerPage"],
            merge: connectionPageMerge,
          },
          promocodes: {
            keyArgs: ["rowsPerPage"],
            merge: connectionPageMerge,
          },
        },
      },
    },
  });
}
