import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ContentCarouselPrevButton from "arena/components/ContentCarouselPrevButton";
import ContentCarouselNextButton from "arena/components/ContentCarouselNextButton";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import SwipeableViews from "react-swipeable-views";
import chunk from "core/utils/chunk";
import Item from "./Item";

const PREFIX = "ContentCarousel";

const classes = {
  root: `${PREFIX}-root`,
  rootServer: `${PREFIX}-rootServer`,
  swipeableViews: `${PREFIX}-swipeableViews`,
  group: `${PREFIX}-group`,
  item: `${PREFIX}-item`,
  grid: `${PREFIX}-grid`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",

  [`& .${classes.rootServer}`]: {},

  [`& .${classes.swipeableViews}`]: {
    margin: theme.spacing(-0.5, -1.5),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, -3),
      padding: theme.spacing(0, 1.5),
    },
  },

  [`& .${classes.group}`]: {
    overflow: "hidden",
    padding: theme.spacing(0, 1.5),
  },

  [`& .${classes.grid}`]: {
    flexWrap: "nowrap",
  },

  [`& .${classes.item}`]: {
    flexShrink: "0",
  },
}));

export default function Carousel({ items, sm, md, lg, xl }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));

  const [index, setIndex] = useState(0);

  const onChangeIndex = (index) => {
    setIndex(index);
  };

  if (process.browser) {
    let groups = [];
    let size = 1;

    if (isXs) {
      size = 2;
    } else if (isSm) {
      size = 12 / sm;
    } else if (isMd) {
      size = 12 / md;
    } else if (isLg) {
      size = 12 / lg;
    } else if (isXl) {
      size = 12 / xl;
    }

    groups = chunk(items, size);

    const prevDisabled = index === 0;
    const nextDisabled = groups.length === index + 1;

    const onClickPrev = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIndex((index) => index - 1 || 0);
    };

    const onClickNext = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIndex((index) => {
        if (index + 1 >= groups.length) {
          return 0;
        }
        return index + 1;
      });
    };

    return (
      <Root>
        <ContentCarouselPrevButton
          disabled={prevDisabled}
          onClick={onClickPrev}
          size={size}
        />

        <ContentCarouselNextButton
          disabled={nextDisabled}
          onClick={onClickNext}
          size={size}
        />

        <SwipeableViews
          disableLazyLoading={true}
          className={classes.swipeableViews}
          index={index}
          onChangeIndex={onChangeIndex}
        >
          {groups.map((group, index) => (
            <div className={classes.group} key={index}>
              <Grid container spacing={3}>
                {group.map((item) => (
                  <Grid
                    item
                    xs={6}
                    sm={sm}
                    md={md}
                    lg={lg}
                    xl={xl}
                    key={item.id}
                    className={classes.item}
                  >
                    <Item item={item} smallTitle={size > 3} />
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </SwipeableViews>
      </Root>
    );
  }

  return (
    <Root>
      <Grid container spacing={3} className={classes.grid}>
        {items.map((item, index) => (
          <Grid
            item
            xs={6}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            key={index}
            className={classes.item}
          >
            <Item item={item} smallTitle={12 / md > 3} />
          </Grid>
        ))}
      </Grid>
    </Root>
  );
}

Carousel.defaultProps = {
  items: [],
};

Carousel.propTypes = {
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      thumbnails: PropTypes.array,
      openInNewWindow: PropTypes.bool,
    })
  ),
};
