import { useArena } from "core/components/ArenaProvider";
import { useQuery } from "@apollo/client";
import { GetUrlByGuidQuery } from "arena/queries";

export default function useGuidUrl(url) {
  const arena = useArena();

  const miniAppUrl = arena?.telegram?.miniAppUrl;
  let guid;

  try {
    guid =
      url?.includes(miniAppUrl) && new URL(url).searchParams.get("startapp");
  } catch (error) {
    // nothing
  }

  const { data } = useQuery(GetUrlByGuidQuery, {
    skip: !guid,
    variables: { guid },
  });

  return data?.getUrlByGuid || url;
}
