import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export default function CommentsDialogHeader({ onClose, count }) {
  const { t } = useTranslation("arena");

  return (
    <AppBar
      position="static"
      color="default"
      sx={{ bgcolor: "background.body" }}
    >
      <Toolbar sx={{ minHeight: 48 }}>
        <Typography variant="h6">
          {t("commentsListHeader.comments", { count })}
        </Typography>
        <IconButton
          sx={{ ml: "auto" }}
          edge="end"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

CommentsDialogHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};
