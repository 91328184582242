import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

function LiveButton({ videoState, videoActions, liveSeekBar }) {
  const { t } = useTranslation("core");
  const [isNotLiveByPause, setIsNotLiveByPause] = useState(false);

  if (!videoState) return null;
  if (!liveSeekBar) return null;
  if (!videoState.live) return null;

  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isNotLiveByPause) {
      setIsNotLiveByPause(false);

      videoActions.seekToLive();
    } else {
      liveSeekBar.setOffset(0);
    }
  };

  useEffect(() => {
    if (!videoState.isUserPause) {
      return;
    }

    if (liveSeekBar.offset !== 0) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsNotLiveByPause(true);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [videoState.isUserPause, liveSeekBar.offset]);

  const isLive = !isNotLiveByPause && liveSeekBar.offset === 0;

  const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="52" height="24">
      <path
        fill="#fff"
        d="M15 18.78V6h2.016v10.998h6.858v1.782H15zM25.5117 18.78V6h2.016v12.78h-2.016zM31.226 6l3.852 10.242L38.912 6h2.142l-5.112 12.78h-1.728L29.084 6h2.142zM51.3812 16.998v1.782h-8.766V6h8.604v1.782h-6.588v3.636h5.706v1.674h-5.706v3.906h6.75z"
      />
      <path
        fill={isLive ? "#F44336" : "rgba(255,255,255,0.3)"}
        d="M9 12.5C9 14.9853 6.98528 17 4.5 17S0 14.9853 0 12.5 2.01472 8 4.5 8 9 10.0147 9 12.5z"
      />
    </svg>
  );

  if (isLive) {
    return (
      <Button onClick={onClick} disabled>
        {icon}
      </Button>
    );
  }

  return (
    <Tooltip title={t("goToLive")}>
      <Button onClick={onClick}>{icon}</Button>
    </Tooltip>
  );
}

LiveButton.propTypes = {
  liveSeekBar: PropTypes.shape({
    setOffset: PropTypes.func.isRequired,
    offset: PropTypes.number,
  }),
  videoState: PropTypes.shape({
    live: PropTypes.bool,
    paused: PropTypes.bool,
    isUserPause: PropTypes.bool,
  }),
  videoActions: PropTypes.shape({
    seekToLive: PropTypes.func.isRequired,
  }),
};

export default LiveButton;
