import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateFavoriteContentMutation,
  DeleteFavoriteContentMutation,
} from "arena/mutations";
import { FavoriteContentQuery } from "arena/queries";
import { useTranslation } from "react-i18next";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useViewer } from "core/components/ViewerProvider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function FavoriteButton({ contentId }) {
  const { t } = useTranslation("arena");
  const viewer = useViewer();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const [createFavoriteContent] = useMutation(CreateFavoriteContentMutation, {
    update(cache) {
      cache.modify({
        fields: {
          favoriteContent() {
            return true;
          },
        },
      });
    },
    variables: {
      contentId,
    },
  });

  const [deleteFavoriteContent] = useMutation(DeleteFavoriteContentMutation, {
    update(cache) {
      cache.modify({
        fields: {
          favoriteContent(favoriteContentRefs, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
    variables: {
      contentId,
    },
  });

  const { data } = useQuery(FavoriteContentQuery, {
    variables: {
      contentId,
    },
  });

  const isFavorite = data?.favoriteContent;

  const onClick = () => {
    if (!viewer) return document.querySelector(".js-sign-in").click();
    if (isFavorite) deleteFavoriteContent();
    else createFavoriteContent();
  };

  return (
    <Button
      variant="text"
      color="inherit"
      startIcon={
        isFavorite ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon />
      }
      size={sm ? "large" : "medium"}
      onClick={onClick}
      sx={{ whiteSpace: "nowrap", borderRadius: 0 }}
    >
      {isFavorite ? t("favoriteButton.saved") : t("favoritButton.save")}
    </Button>
  );
}

FavoriteButton.propTypes = {
  contentId: PropTypes.string.isRequired,
};
