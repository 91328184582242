import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function AppHelmet({ arena }) {
  const { i18n } = useTranslation("arena");
  const { pathname } = useLocation();

  const canonicalUrl = new URL(pathname, arena.url);

  return (
    <Helmet titleTemplate={`%s - ${arena.title}`} defaultTitle={arena.title}>
      <html lang={i18n.language} />
      <link rel="canonical" href={canonicalUrl.href} />

      {arena.favicon ? (
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={arena.favicon.url}
        />
      ) : arena.logo ? (
        <link rel="icon" type="image/png" sizes="32x32" href={arena.logo.url} />
      ) : null}

      {arena.logo && (
        <link rel="apple-touch-icon" sizes="512x512" href={arena.logo.url} />
      )}
      {arena.logo && (
        <meta name="msapplication-TileImage" content={arena.logo.url} />
      )}
      <link rel="manifest" href="/arena.webmanifest" />

      <meta name="application-name" content={arena.title} />
      <meta name="apple-mobile-web-app-title" content={arena.title} />
      <meta name="msapplication-starturl" content="/?utm_source=pwa" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="apple-mobile-web-app-title" content={arena.title} />

      {arena?.icon?.thumbnails?.map((t, i) => (
        <link
          rel="apple-touch-icon"
          sizes={`${t.width}x${t.height}`}
          href={t.url}
          key={i}
        />
      ))}

      {/* {arena.icon.thumbnails.map((t, i) => (
        <link
          rel="apple-touch-startup-image"
          sizes={`${t.width}x${t.height}`}
          href={t.url}
          key={i}
        />
      ))} */}

      <meta name="theme-color" content="#000" />

      {arena.googleSiteVerification && (
        <meta
          name="google-site-verification"
          content={arena.googleSiteVerification}
        />
      )}
    </Helmet>
  );
}

AppHelmet.propTypes = {
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    googleSiteVerification: PropTypes.string,
    logo: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    favicon: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
    icon: PropTypes.shape({
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          width: PropTypes.number,
          height: PropTypes.number,
          url: PropTypes.string,
        })
      ),
    }),
    theme: PropTypes.shape({
      palette: PropTypes.shape({
        primary: PropTypes.string,
        secondary: PropTypes.string,
      }),
    }),
  }),
};
