import { loader } from "graphql.macro";

export const DisconnectDeviceMutation = loader(
  "./DisconnectDeviceMutation.graphql"
);
export const ConnectDeviceMutation = loader("./ConnectDeviceMutation.graphql");

export const CreateOnboardingAnswerMutation = loader(
  "./CreateOnboardingAnswerMutation.graphql"
);
export const SignOutMutation = loader("./SignOutMutation.graphql");

export const UpdateSettingsMutation = loader(
  "./UpdateSettingsMutation.graphql"
);

export const UploadAvatarMutation = loader("./UploadAvatarMutation.graphql");

export const CreateProductFeedbackMutation = loader(
  "./CreateProductFeedbackMutation.graphql"
);
export const CreatePaymentMutation = loader("./CreatePaymentMutation.graphql");
export const CompletePaymentMutation = loader(
  "./CompletePaymentMutation.graphql"
);

export const DeletePaymentMethodMutation = loader(
  "./DeletePaymentMethodMutation.graphql"
);

export const UnsubscribeMutation = loader("./UnsubscribeMutation.graphql");
export const DisableSubscriptionMutation = loader(
  "./DisableSubscriptionMutation.graphql"
);
export const UpdateProductPurchaseMutation = loader(
  "./UpdateProductPurchaseMutation.graphql"
);

export const CreateFavoriteContentMutation = loader(
  "./CreateFavoriteContentMutation.graphql"
);

export const DeleteFavoriteContentMutation = loader(
  "./DeleteFavoriteContentMutation.graphql"
);

export const DeleteAccountMutation = loader("./DeleteAccountMutation.graphql");
export const SignInByTelegramMutation = loader(
  "./SignInByTelegramMutation.graphql"
);

export const CreateLikeMutation = loader("./CreateLikeMutation.graphql");
export const DeleteLikeMutation = loader("./DeleteLikeMutation.graphql");

export const CreateCommentMutation = loader("./CreateCommentMutation.graphql");
export const UpdateCommentMutation = loader("./UpdateCommentMutation.graphql");
export const DeleteCommentMutation = loader("./DeleteCommentMutation.graphql");
