import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { textFormat, truncate } from "core/utils/string";
import { useTranslation } from "react-i18next";

const MAX_LENGTH = 100;

export default function CommentListItemText({ message }) {
  const { t } = useTranslation("arena");
  const isExpandable = message.length > MAX_LENGTH;
  const [isExpanded, setExpanded] = React.useState(isExpandable);

  const __html =
    isExpandable && isExpanded
      ? textFormat(truncate(message, MAX_LENGTH))
      : textFormat(message);

  return (
    <>
      <Typography
        variant="body2"
        color="text.primary"
        gutterBottom
        dangerouslySetInnerHTML={{ __html }}
        sx={{
          "& a": {
            color: "#81d4fa",
          },
          "& p": {
            "&:first-of-type": {
              marginTop: 0,
            },
            "&:last-child": {
              marginBottom: 0,
            },
          },
        }}
      />
      {isExpanded && isExpandable && (
        <Link
          color="primary"
          variant="body2"
          display="inline"
          onClick={() => setExpanded(false)}
          underline="hover"
          sx={{ cursor: "pointer" }}
        >
          {t("commentListItemText.readmore")}
        </Link>
      )}
    </>
  );
}

CommentListItemText.propTypes = {
  message: PropTypes.string.isRequired,
};
