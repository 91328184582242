import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { useViewer } from "core/components/ViewerProvider";
import Stack from "@mui/material/Stack";
import { truncate } from "core/utils/string";
import { useTranslation } from "react-i18next";

export default function CommentsCard({ comments, count, onClick }) {
  const { t } = useTranslation("arena");
  const viewer = useViewer();
  const firstComment = comments[0];
  const viewerPhotoUrl = viewer?.photo?.url || viewer?.photoUrl;
  const firstCommentCreatorPhotoUrl =
    firstComment?.creator?.photo?.url || firstComment?.creator?.photoUrl;
  const avatarUrl = count === 0 ? viewerPhotoUrl : firstCommentCreatorPhotoUrl;

  return (
    <Box sx={{ pl: { xs: 2, sm: 3 }, pr: { xs: 2, sm: 3 } }}>
      <Card>
        <CardActionArea onClick={onClick}>
          <CardContent sx={{ pt: 1.5 }}>
            <Box sx={{ mb: 1 }}>
              <Typography
                variant="subtitle2"
                color="text.primary"
                display={"inline"}
              >
                {t("commentsCard.comments")}
              </Typography>
              {count > 0 && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  display={"inline"}
                  sx={{ ml: 1 }}
                >
                  {count}
                </Typography>
              )}
            </Box>
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <Avatar sx={{ width: 31, height: 31 }} src={avatarUrl} />
              {count === 0 && (
                <Box
                  sx={{
                    p: 1,
                    pl: 1.5,
                    pr: 1.5,
                    bgcolor: "background.paper",
                    color: "text.secondary",
                    borderRadius: 10,
                  }}
                >
                  {t("commentsCard.writeYourCommentHere")}
                </Box>
              )}
              {count > 0 && firstComment && (
                <Typography variant="body2" color="text.primary">
                  {truncate(firstComment.message, 60)}
                </Typography>
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

CommentsCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  comments: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
};
