import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

const PREFIX = "HomepageTitle";

const classes = {
  title: `${PREFIX}-title`,
};

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  margin: 0,
  padding: theme.spacing(3, 2, 2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 0, 2),
  },
  display: "flex",
  alignItems: "center",
  flexWrap: "nowrap",

  [`& .${classes.title}`]: {
    fontWeight: 600,
    // padding: theme.spacing(1, 0, 1),
    marginRight: theme.spacing(0.5),

    fontSize: "1.2rem",
    [theme.breakpoints.up("sm")]: {
      // padding: theme.spacing(0, 0, 1),
      fontSize: "1.6rem",
    },
  },
}));

// TODO надо переименовать в RowTitle
export default function HomepageTitle({
  variant,
  loading,
  title,
  className,
  action,
  sx,
}) {
  return (
    <Root className={className} sx={sx}>
      {loading ? (
        <Typography
          variant={variant}
          component="h2"
          noWrap
          className={classes.title}
        >
          <Skeleton variant="text" width={300} height={48} />
        </Typography>
      ) : (
        <>
          <Typography
            variant={variant}
            component="h2"
            noWrap
            className={classes.title}
          >
            {title}
          </Typography>
          {action}
        </>
      )}
    </Root>
  );
}

HomepageTitle.defaultProps = {
  variant: "h4",
};

HomepageTitle.propTypes = {
  sx: PropTypes.object,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  moreUrl: PropTypes.string,
  action: PropTypes.node,
  loading: PropTypes.bool,
};
