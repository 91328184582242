import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import useIsMobile from "arena/hooks/useIsMobile";

export default function CommentsDialog({
  open,
  onClose,
  children,
  isInputInFocus,
}) {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!process.browser) return;

    const routeDialogScrollable = document.querySelector(
      "#route-dialog-scrollable"
    );

    if (!routeDialogScrollable) return;

    routeDialogScrollable.style.overflowY = open ? "hidden" : "auto";

    if (open) {
      routeDialogScrollable.scrollTo(0, 0);
    }
  }, [open]);

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={open}
      onClose={onClose}
      elevation={0}
      disablePortal // это нужно чтобы в миниапе не прыгало
      disableAutoFocus // это нужно чтобы в миниапе не прыгало
      disableRestoreFocus
      keepMounted // это нужно чтобы в миниапе не прыгало
      sx={{
        zIndex: 1300,

        position: {
          xs: "absolute",
          md: "fixed",
        },
        // height: "auto",
        // pointerEvents: "none",
        // top: 120, //(window.innerWidth / 16) * 9,
        "&.MuiDrawer-root": {
          display: open ? "flex" : "none", // из-за этого скрытие происходит моментально, но без этого компонент прячется просто через visibility: hidden и занимает место на странице
          // position: "absolute",
          height: isInputInFocus ? "100%" : "calc(100vh - 56.25vw)",
          top: 0,
          marginTop: {
            xs: isInputInFocus ? 0 : "calc(100vw / 16 * 9)",
            md: 0,
          },
          transition: "all ease 300ms",
          bgcolor: "background.body",
        },
        "& > .MuiDrawer-paper": {
          position: "absolute",
          height: "100%",
          // bgcolor: "background.body",
          bgcolor: "background.header",
          // display: open ? "flex" : "none",
        },
      }}
      hideBackdrop={isMobile}
    >
      {children}
    </Drawer>
  );
}

CommentsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isInputInFocus: PropTypes.bool,
};
