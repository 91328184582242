import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useQuery } from "@apollo/client";
import { PostQuery } from "arena/queries";
import PostHelmet from "./PostHelmet";
import ErrorScreen from "core/components/ErrorScreen";
import NoMatchScreen from "arena/components/NoMatchScreen";
// import useScrollToTop from "core/hooks/useScrollToTop";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ThumbnailCardMedia from "arena/components/ThumbnailCardMedia";
import TagList from "arena/components/TagList";
import { CloseRouteDialogButton } from "arena/components/RouteDialog";
import PaymentButtons from "core/components/PaymentButtons";
import PaymentsIcon from "@mui/icons-material/Payments";
import { useArena } from "core/components/ArenaProvider";
import useProductDialog from "arena/hooks/useProductDialog";
import ProductDialog from "arena/components/ProductDialog";

const Text = styled(Typography)({
  "& img": {
    maxWidth: "100%",
  },
});

const StyledThumbnailCardMedia = styled(ThumbnailCardMedia)(() => ({
  width: "100%",
  position: "relative",
  height: 0,
  paddingTop: "56.25%", // 16:9
  backgroundColor: "black",
}));

export default function Post({ params: { postId } }) {
  const location = useLocation();
  const { t } = useTranslation("arena");
  const arena = useArena();
  const { state, dispatch } = useProductDialog({});

  const onCompleted = (data) => {
    if (data.post) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.post.title,
      });
    }
  };

  const { data, loading, error, refetch } = useQuery(PostQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: postId,
    },
    onCompleted,
  });

  const post = data?.post;

  // useScrollToTop();

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!post && !loading) {
    return <NoMatchScreen />;
  }

  return (
    <>
      {post && <PostHelmet post={post} />}

      {post && (
        <Container maxWidth="md" sx={{ p: 0 }}>
          <Card sx={{ backgroundColor: "rgba(255,255,255,0.03)" }}>
            <StyledThumbnailCardMedia
              thumbnails={post.image.thumbnails}
              alt={post.title}
              size="MAXRES"
            >
              <CloseRouteDialogButton />
            </StyledThumbnailCardMedia>
            <CardHeader
              title={post.title}
              subheader={t("publishAt", { date: post.publishAt })}
              titleTypographyProps={{ sx: { mb: 2 } }}
            />
            {post.tags.length > 0 && (
              <Box sx={{ pb: 2 }}>
                <TagList tags={post.tags} />
              </Box>
            )}
            <CardContent sx={{ pt: 0 }}>
              <Typography
                variant="subheader1"
                color="textPrimary"
                dangerouslySetInnerHTML={{
                  __html: post.premiumDescription || post.description,
                }}
              />

              {post.premium && !post.viewerPurchase ? (
                <Box sx={{ m: 2, p: 2 }}>
                  <ErrorScreen
                    title={t("paidContent.title")}
                    text={t("paidContent.description")}
                    icon={PaymentsIcon}
                    button={
                      <PaymentButtons
                        products={post.products.nodes.filter(
                          (product) => product.prices.length
                        )}
                        isTelegramArena={arena?.isTelegramArena}
                        onClickProductButton={dispatch}
                      />
                    }
                  />
                </Box>
              ) : (
                <Text
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: post.text }}
                />
              )}
            </CardContent>
          </Card>
          <ProductDialog
            products={post.products.nodes}
            state={state}
            dispatch={dispatch}
            refetch={refetch}
          />
        </Container>
      )}
    </>
  );
}

Post.propTypes = {
  params: PropTypes.shape({
    postId: PropTypes.string.isRequired,
  }),
};
