import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Likes from "arena/components/Likes";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReplyIcon from "@mui/icons-material/Reply";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

export default function CommentListItemControls({
  canReply,
  onClickReply,
  comment,
}) {
  const { t } = useTranslation("arena");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  if (!canReply && !comment.likes) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} sx={{ color: "text.secondary" }}>
      {comment.likes && <Likes commentId={comment.id} likes={comment.likes} />}

      {canReply && (
        <Tooltip title={t("commentListItemControls.reply")}>
          <Button
            size={sm ? "large" : "medium"}
            onClick={() => onClickReply(comment)}
            sx={{ minWidth: 40 }}
            variant="text"
            color="inherit"
          >
            <ReplyIcon />
          </Button>
        </Tooltip>
      )}
    </Stack>
  );
}

CommentListItemControls.propTypes = {
  canReply: PropTypes.bool,
  onClickReply: PropTypes.func.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    creator: PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      photoUrl: PropTypes.string,
      photo: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    likes: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
    replyToComment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  }),
};
