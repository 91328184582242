import React from "react";
import PropTypes from "prop-types";
import { ProductDonationProgressQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import DonationProgress from "arena/components/DonationProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "arena/components/Link";
import { useTranslation } from "react-i18next";

export default function ArenaDonationProgress({
  productId,
  paddingTop,
  paddingBottom,
  maxWidth,
  backgroundColor,
}) {
  const { t } = useTranslation("arena");
  const { data } = useQuery(ProductDonationProgressQuery, {
    skip: !productId,
    variables: {
      id: productId,
    },
  });

  if (!productId) return null;
  if (!data?.product) return null;

  const { donationProgress, title, siteUrl } = data.product;

  if (!donationProgress) return null;

  return (
    <div style={{ backgroundColor }}>
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop,
          paddingBottom,
          maxWidth,
          margin: "auto",
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DonationProgress product={data.product} />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={siteUrl}
              fullWidth
              size="large"
            >
              {t("productPayment.donate")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

ArenaDonationProgress.propTypes = {
  productId: PropTypes.string,
  backgroundColor: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  maxWidth: PropTypes.string,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};
