import { loader } from "graphql.macro";

export const ChatMessageCreatedSubscription = loader(
  "./ChatMessageCreatedSubscription.graphql"
);

export const ChatMessageDeletedSubscription = loader(
  "./ChatMessageDeletedSubscription.graphql"
);

export const BanCreatedSubscription = loader(
  "./BanCreatedSubscription.graphql"
);

export const SessionClosedSubscription = loader(
  "./SessionClosedSubscription.graphql"
);

export const ViewSessionClosedSubscription = loader(
  "./ViewSessionClosedSubscription.graphql"
);

export const BanDeletedSubscription = loader(
  "./BanDeletedSubscription.graphql"
);

export const LiveEventCreatedSubscription = loader(
  "./LiveEventCreatedSubscription.graphql"
);

export const ContentStreamStatusChangedSubscription = loader(
  "./ContentStreamStatusChangedSubscription.graphql"
);

export const ContentViewersOnlineChangedSubscription = loader(
  "./ContentViewersOnlineChangedSubscription.graphql"
);

export const ViewStreamStatusChangedSubscription = loader(
  "./ViewStreamStatusChangedSubscription.graphql"
);
