import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import LoadMore from "core/components/LoadMore";

import ProductCarouselItem from "arena/components/ProductCarouselItem";

export default function ProductList({
  nodes,
  // count,
  // loadMore,
  // loading,
  // rowsPerPage,
  xs,
  sm,
  md,
  lg,
  xl,
  title,
  onLoadMore,
  showLoadMore,
  nextPage,
  onClickPromoVideoButton,
}) {
  return (
    <>
      {title}

      <Grid
        container
        spacing={3}
        alignItems="stretch"
        data-testid="ProductList"
      >
        {nodes.map((product) => (
          <Grid
            item
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            key={product.id}
            style={{ display: "flex" }}
            data-testid="ProductListItem"
          >
            <ProductCarouselItem
              product={product}
              onClickPromoVideoButton={onClickPromoVideoButton}
            />
          </Grid>
        ))}
      </Grid>

      {showLoadMore && <LoadMore key={nextPage} onLoadMore={onLoadMore} />}
    </>
  );
}

ProductList.propTypes = {
  onClickPromoVideoButton: PropTypes.func,
  showLoadMore: PropTypes.bool,
  nextPage: PropTypes.number,
  onLoadMore: PropTypes.func,
  loading: PropTypes.bool,
  nodes: PropTypes.array,
  count: PropTypes.number,
  title: PropTypes.node,
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  rowsPerPage: PropTypes.number,
  fetchPolicy: PropTypes.string,
  empty: PropTypes.node,
  type: PropTypes.string,
  arenaId: PropTypes.string,
  rightholderIds: PropTypes.arrayOf(PropTypes.string),
  loadMore: PropTypes.bool,
};

ProductList.defaultProps = {
  empty: null,
  fetchPolicy: "cache-and-network",
  rowsPerPage: 10,
  xs: 12,
  sm: 6,
  md: 4,
  // lg: 3,
  // xl: 2,
};
