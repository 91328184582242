import React from "react";
import { useArena } from "core/components/ArenaProvider";
import NoMatchScreen from "arena/components/NoMatchScreen";
import { useLocation } from "react-router-dom";
import PageHelmet from "./PageHelmet";
import PageSection from "./PageSection";
import usePageview from "core/hooks/usePageview";
import LoadingScreen from "core/components/LoadingScreen";
import ErrorScreen from "core/components/ErrorScreen";
import { useQuery } from "@apollo/client";
import { ArenaPageQuery } from "arena/queries";

const COVER = "COVER";
const COVER_CAROUSEL = "COVER_CAROUSEL";

/**
 * Функция принимает массив блоков для страницы и возвращает массив блоков
 * в котором блоки типа "Обложка" сгруппированы в карусельку.
 */
function groupBlocks(blocks) {
  const result = [];

  blocks.forEach((block) => {
    const last = result[result.length - 1];

    if (block.component === COVER) {
      if (last?.component === COVER_CAROUSEL) {
        last.props.blocks.push(block);
      } else {
        result.push({
          id: block.id,
          component: COVER_CAROUSEL,
          props: {
            blocks: [block],
          },
        });
      }
    } else {
      result.push(block);
    }
  });

  return result;
}

export default function Page() {
  const arena = useArena();
  const location = useLocation();

  const { data, previousData, loading, error } = useQuery(ArenaPageQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      path: location.pathname,
    },
  });

  const page = data?.page || previousData?.page;

  usePageview(location.pathname, page?.title);

  if (loading) return <LoadingScreen />;
  if (error) return <ErrorScreen error={error} />;
  if (!page) {
    return <NoMatchScreen />;
  }

  const blocks = groupBlocks(page.blocks);

  return (
    <>
      <PageHelmet arena={arena} page={page} />
      {blocks.map((block, index) => {
        return (
          <PageSection
            block={block}
            arena={arena}
            index={index}
            first={index === 0}
            last={index + 1 === blocks.length}
            key={block.id}
          />
        );
      })}
    </>
  );
}
