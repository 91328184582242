import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useArena } from "core/components/ArenaProvider";

const PROFILE = "PROFILE";
const PAYMENT = "PAYMENT";
const AUTH = "AUTH";
const DEVICES = "DEVICES";
const PROMOCODES = "PROMOCODES";
const ACCOUNT = "ACCOUNT";

function SettingsTabs({ enableOauth, tab, onChangeTab }) {
  const { t } = useTranslation("arena");
  const arena = useArena();

  return (
    <Tabs
      value={tab}
      onChange={onChangeTab}
      indicatorColor="primary"
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab label={t("settingsTabs.profile")} value={PROFILE} />

      {!arena.isTelegramArena && (
        <Tab label={t("settingsTabs.payment")} value={PAYMENT} />
      )}

      <Tab label={t("settingsTabs.promocodes")} value={PROMOCODES} />

      {!arena.isTelegramArena && (
        <Tab label={t("settingsTabs.devices")} value={DEVICES} />
      )}

      {enableOauth && <Tab label={t("settingsTabs.auth")} value={AUTH} />}

      {!arena.isTelegramArena && (
        <Tab label={t("settingsTabs.account")} value={ACCOUNT} />
      )}
    </Tabs>
  );
}

SettingsTabs.propTypes = {
  tab: PropTypes.oneOf([PROFILE, PAYMENT, AUTH, DEVICES, PROMOCODES, ACCOUNT])
    .isRequired,
  onChangeTab: PropTypes.func.isRequired,
  enableOauth: PropTypes.bool.isRequired,
};

export default withSnackbar(SettingsTabs);
