import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { textFormat } from "core/utils/string";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

export default function ContentDescription({ children }) {
  const textRef = useRef();
  const description = textFormat(children);
  const [expandable, setExpandable] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const expandable =
      textRef.current.scrollHeight > textRef.current.clientHeight;
    setExpandable(expandable);
  }, [children]);

  const onClickButton = useCallback(() => setExpanded((v) => !v), []);

  return (
    <Box
      sx={{
        pl: {
          xs: 2,
          sm: 3,
        },
        pr: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      <Card elevation={0} sx={{ position: "relative", p: 2, pr: 6 }}>
        <Typography
          component="div"
          variant="body2"
          color="textSecondary"
          dangerouslySetInnerHTML={{ __html: description }}
          ref={textRef}
          sx={{
            overflow: "hidden",
            maxHeight: {
              xs: expanded ? "auto" : "calc(0.8rem * 3)",
              sm: expanded ? "auto" : "calc(1.1rem * 3)",
            },
            fontSize: {
              xs: "0.8rem",
              sm: "1.1rem",
            },
            "& a": {
              color: "#81d4fa",
            },
            "& p": {
              "&:first-of-type": {
                marginTop: 0,
              },
              "&:last-child": {
                marginBottom: 0,
              },
            },
          }}
        />

        {expandable && (
          <IconButton
            onClick={onClickButton}
            sx={{
              position: "absolute",
              top: 6,
              right: 6,
            }}
          >
            {!expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowLeftIcon />}
          </IconButton>
        )}
      </Card>
    </Box>
  );
}

ContentDescription.propTypes = {
  children: PropTypes.string,
};
