import React from "react";
import PropTypes from "prop-types";
import ArenaContents from "arena/components/ArenaContents";
import Box from "@mui/material/Box";
import { useQuery } from "@apollo/client";
import { ContentPlaylistQuery } from "arena/queries";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import Link from "arena/components/Link";
// import IconButton from "@mui/material/IconButton";
// import CardHeader from "@mui/material/CardHeader";
// import AppBar from "@mui/material/AppBar";

export default function RelatedContent({
  limit,
  type,
  status,
  relatedContentId,
  selectedContentId,
  productId,
  collectionId,
  isMobile,
}) {
  const { data, loading } = useQuery(ContentPlaylistQuery, {
    skip: !productId && !collectionId,
    variables: {
      productId: productId || selectedContentId,
      contentId: collectionId || selectedContentId,
      loadContent: !!collectionId,
      loadProduct: !!productId,
    },
  });

  if (loading) return null;

  // const title = (data?.product || data?.content)?.title;
  // const link = (data?.product || data?.content)?.siteUrl;
  const sort = data?.content?.collectionContentSort || "LATEST";

  return (
    <>
      {/* {title && (
        <AppBar
          position="static"
          elevation={0}
          color="default"
          sx={{ bgcolor: "background.default" }}
        >
          <CardHeader
            sx={{ py: 1 }}
            title={title || "Test"}
            // titleTypographyProps={{ sx: { fontWeight: 800 } }}
            avatar={
              link && (
                <IconButton component={Link} to={link}>
                  <ArrowBackIcon />
                </IconButton>
              )
            }
          />
        </AppBar>
      )} */}
      <Box
        sx={
          isMobile ? { py: 2, overflowY: "none" } : { p: 0, overflowY: "auto" }
        }
      >
        <ArenaContents
          collectionId={collectionId}
          inView
          lg={12}
          limit={limit}
          loadMore
          md={12}
          productId={productId}
          relatedContentId={collectionId || relatedContentId}
          selectedContentId={selectedContentId}
          sm={12}
          sort={sort}
          status={status}
          type={type}
          view="LIST"
          xl={12}
          xs={12}
        />
      </Box>
    </>
  );
}

RelatedContent.propTypes = {
  isMobile: PropTypes.bool,
  limit: PropTypes.number,
  type: PropTypes.string,
  status: PropTypes.string,
  relatedContentId: PropTypes.string,
  selectedContentId: PropTypes.string,
  productId: PropTypes.string,
  collectionId: PropTypes.string,
};
