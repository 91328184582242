import { useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import Fade from "@mui/material/Fade";
import { ArenaCustomCoversQuery } from "../../queries";
import ArenaCustomCover from "../ArenaCustomCover";

const PREFIX = "ArenaCustomCoverCarousel";

const classes = {
  item: `${PREFIX}-item`,
  dots: `${PREFIX}-dots`,
  dot: `${PREFIX}-dot`,
  dotActive: `${PREFIX}-dotActive`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  height: "100vh",
  marginBottom: -100,
  marginTop: -54,
  [theme.breakpoints.up("sm")]: {
    marginTop: -64,
  },
  [theme.breakpoints.up("sm")]: {
    height: "100vh",
  },

  [`& .${classes.item}`]: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "45%",
    transform: "translateY(-50%)",
  },

  [`& .${classes.dots}`]: {
    zIndex: 10,
    position: "absolute",
    left: 10,
    right: 10,
    display: "flex",
    justifyContent: "center",
    bottom: 120,
  },

  [`& .${classes.dot}`]: {
    padding: 0,
    border: 0,
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: "rgba(255, 255, 255, 0.25)",
    margin: 10,
    cursor: "pointer",
  },

  [`& .${classes.dotActive}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
}));

export default function ArenaCustomCoverCarousel({ arena }) {
  const { data: arenaCustomCoversData } = useQuery(ArenaCustomCoversQuery);
  const customCoversBlock = arenaCustomCoversData
    ? arenaCustomCoversData?.arenaCustomCovers?.nodes?.map((customCover) => {
        return {
          component: "CUSTOM_COVER",
          id: customCover.id,
          props: customCover,
        };
      })
    : [];
  const [autoPlay, setAutoPlay] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (!autoPlay) return;
    if (!process.browser) return;
    if (customCoversBlock.length === 1) return;

    const timeout = setTimeout(() => {
      setSlideIndex((slideIndex) => {
        const nextIndex = slideIndex + 1;
        if (customCoversBlock[nextIndex]) {
          return nextIndex;
        }
        return 0;
      });
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [slideIndex, autoPlay, customCoversBlock]);

  const onChangeIndex = (event, slideIndex) => {
    setSlideIndex(slideIndex);
    setAutoPlay(false);
  };

  if (customCoversBlock.length === 0) {
    return null;
  }

  if (customCoversBlock.length === 1) {
    const [block] = customCoversBlock;
    return (
      <ArenaCustomCover arena={arena} {...block.props} blockId={block.id} />
    );
  }

  return (
    <Root>
      {customCoversBlock.map((block, index) => (
        <Fade
          timeout={1000}
          appear={false}
          key={block.id}
          in={index === slideIndex}
        >
          <div className={classes.item}>
            <ArenaCustomCover
              arena={arena}
              {...block.props}
              blockId={block.id}
              index={index + 1}
            />
          </div>
        </Fade>
      ))}

      <div className={classes.dots}>
        {customCoversBlock.map((block, index) => (
          <button
            aria-label={`Slide ${index + 1}`}
            key={block.id}
            className={classNames({
              [classes.dot]: true,
              [classes.dotActive]: index === slideIndex,
            })}
            onClick={(event) => onChangeIndex(event, index)}
          />
        ))}
      </div>
    </Root>
  );
}

ArenaCustomCoverCarousel.propTypes = {
  arena: PropTypes.object.isRequired,
};
