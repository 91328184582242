import React from "react";
import PropTypes from "prop-types";
import MuiTab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";

export default function Tabs({ tab, onChangeTab, tabs }) {
  return (
    <AppBar
      elevation={0}
      color="default"
      position="static"
      sx={{ bgcolor: "background.body" }}
    >
      <MuiTabs
        value={tab}
        onChange={onChangeTab}
        indicatorColor="primary"
        variant="fullWidth"
        scrollButtons="auto"
      >
        {tabs.map((t) => (
          <MuiTab key={t.label} label={t.label} value={t.value} />
        ))}
      </MuiTabs>
    </AppBar>
  );
}

Tabs.propTypes = {
  tab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};
