import React, { useEffect, useState } from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { withSnackbar } from "notistack";

import { loader } from "graphql.macro";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";

const CreateTelegramAuthMutation = loader(
  "./CreateTelegramAuthMutation.graphql"
);
const TelegramAuthQuery = loader("./TelegramAuthQuery.graphql");

const PREFIX = "SignInProviders";

const classes = {
  dividerContainer: `${PREFIX}-dividerContainer`,
  divider: `${PREFIX}-divider`,
  dividerText: `${PREFIX}-dividerText`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.dividerContainer}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.divider}`]: {
    borderBottom: "2px solid #797979",
    width: "100%",
  },

  [`& .${classes.dividerText}`]: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    textAlign: "center",
    flexShrink: 0,
    color: "#797979",
  },

  [`& .${classes.button}`]: {
    backgroundColor: "#fff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
    },
    "&:focus": {
      backgroundColor: "#fff",
      color: "#000",
    },
    "&.Mui-disabled": {
      backgroundColor: "#fff",
      color: "#000",
      opacity: 0.5,
    },
  },
}));

function TelegramMiniAppWebSignIn({
  disabled,
  // needAcceptTermsOfService,
  enqueueSnackbar,
}) {
  const client = useApolloClient();
  const { t } = useTranslation("core");
  const [auth, setAuth] = useState();

  const onCompleted = (data) => {
    setAuth(data.createTelegramAuth);

    window.open(data.createTelegramAuth.botUrl, "_blank");
  };

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const [createTelegramAuth, { loading }] = useMutation(
    CreateTelegramAuthMutation,
    {
      variables: {
        input: {
          referer: window.location.href,
        },
      },
      onCompleted,
      onError,
    }
  );

  const { data } = useQuery(TelegramAuthQuery, {
    pollInterval: 3000,
    variables: {
      id: auth?.id,
    },
    skip: !auth?.id,
  });

  useEffect(() => {
    if (!data?.telegramAuth?.user) {
      return;
    }

    if (data.telegramAuth.user) {
      client.resetStore();
    }
  }, [data]);

  const onClickLogin = () => {
    if (auth?.botUrl) {
      window.open(auth.botUrl, "_blank");
    } else {
      createTelegramAuth();
    }
  };

  return (
    <Root className={classes.root}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Button
            variant="outlined"
            disabled={disabled || loading}
            fullWidth
            onClick={() => onClickLogin()}
            startIcon={
              <TelegramIcon
                sx={{
                  color: "#0088cc",
                }}
              />
            }
            size="large"
            color="inherit"
            className={classes.button}
          >
            {t("signIn.loginWithTelegram")}
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
}

TelegramMiniAppWebSignIn.propTypes = {
  disabled: PropTypes.bool,
  needAcceptTermsOfService: PropTypes.bool,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(TelegramMiniAppWebSignIn);
