import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { truncate } from "core/utils/string";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export default function ReplyCommentPreview({ comment, onClickClose }) {
  const { t } = useTranslation("arena");
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        px: { xs: 1, sm: 2 },
        pt: 1,
        pb: { xs: 1, sm: 0 },
      }}
    >
      <ReplyAllIcon color="primary" />

      <Box
        sx={{
          borderLeft: "2px solid",
          borderColor: "primary.main",
          pl: { xs: 1, sm: 2 },
          ml: { xs: 1, sm: 2 },
          mr: "auto",
          width: "100%",
          // maxWidth: "calc(100vw - 100px)",
        }}
      >
        <Typography variant="caption" color="primary" display={"block"}>
          {t("replyCommentPreview.inReplyTo", {
            fullName: comment.creator.fullName,
          })}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          display={"block"}
          noWrap
        >
          {truncate(comment.message, 40)}
        </Typography>
      </Box>

      <IconButton size="small" color="primary" onClick={onClickClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
}

ReplyCommentPreview.propTypes = {
  onClickClose: PropTypes.func.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    creator: PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    }),
  }),
};
