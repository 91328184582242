import {
  init,
  postEvent,
  backButton,
  isTMA,
  isFullscreen,
  retrieveLaunchParams,
  viewport,
} from "@telegram-apps/sdk";
import { useEffect } from "react";
import { GetUrlByGuidQuery } from "arena/queries";
import { useQuery } from "@apollo/client";

export const isTelegramMiniApp = isTMA("simple");
export const isTelegramMiniAppOnFullscreen =
  isTelegramMiniApp && isFullscreen();

export const launchParams = isTelegramMiniApp ? retrieveLaunchParams() : null;

export const webAppVersion = launchParams?.version || "6.0";

if (isTelegramMiniApp) {
  // Init the package and actualize all global dependencies.
  init({
    acceptCustomStyles: false,
  });

  const launchParams = retrieveLaunchParams();

  console.log("launchParams", launchParams);

  postEvent("web_app_expand");
  postEvent("web_app_setup_closing_behavior", { need_confirmation: false }); // eslint-disable-line camelcase
  postEvent("web_app_setup_swipe_behavior", { allow_vertical_swipe: false }); // eslint-disable-line camelcase

  if (viewport.mount.isAvailable()) {
    viewport.mount();
    console.log("viewport mounted", viewport.isMounted()); // true
    console.log("viewport.safeAreaInsets", viewport.safeAreaInsets());
    console.log(
      "viewport.contentSafeAreaInsets",
      viewport.contentSafeAreaInsets()
    );
  }
}

export function openLink(_url, options) {
  var a = document.createElement("a");
  a.href = _url;
  options = options || {};

  const url = a.href;

  if (versionAtLeast("6.1")) {
    let reqParams = { url: url };
    if (versionAtLeast("6.4") && options.try_instant_view) {
      reqParams.try_instant_view = true; // eslint-disable-line camelcase
    }
    if (versionAtLeast("7.6") && options.try_browser) {
      reqParams.try_browser = options.try_browser; // eslint-disable-line camelcase
    }
    postEvent("web_app_open_link", reqParams);
  } else {
    window.open(url, "_blank");
  }
}

function versionAtLeast(ver) {
  return versionCompare(webAppVersion, ver) >= 0;
}

function versionCompare(v1, v2) {
  if (typeof v1 !== "string") v1 = "";
  if (typeof v2 !== "string") v2 = "";
  v1 = v1.replace(/^\s+|\s+$/g, "").split(".");
  v2 = v2.replace(/^\s+|\s+$/g, "").split(".");
  var a = Math.max(v1.length, v2.length),
    i,
    p1,
    p2;
  for (i = 0; i < a; i++) {
    p1 = parseInt(v1[i]) || 0;
    p2 = parseInt(v2[i]) || 0;
    if (p1 === p2) continue;
    if (p1 > p2) return 1;
    return -1;
  }
  return 0;
}

export function useReactRouterIntegration(location, navigate) {
  useEffect(() => {
    if (!isTelegramMiniApp) return;

    postEvent("web_app_setup_back_button", {
      is_visible: location.pathname !== "/", // eslint-disable-line camelcase
    });

    // When a user clicked the back button, go back in the
    // navigation history.
    if (backButton.onClick.isAvailable()) {
      const off = backButton.onClick(() => {
        off();
        navigate(-1);
      });

      return off;
    }
  }, [location, navigate]);

  useEffect(() => {
    if (!isTelegramMiniApp) return;

    postEvent("web_app_setup_title", { title: document.title }); // eslint-disable-line camelcase
  }, [location]);

  let startParam;
  if (isTelegramMiniApp) {
    const launchParams = retrieveLaunchParams();
    startParam = launchParams?.startParam;
  }

  useQuery(GetUrlByGuidQuery, {
    skip: !isTelegramMiniApp || !startParam,
    variables: { guid: startParam },
    onCompleted: (data) => {
      if (data?.getUrlByGuid) {
        navigate(data.getUrlByGuid);
      }
    },
  });
}
