import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import formatCurrency from "core/utils/currency";
import getOldPriceButtonText from "core/utils/getOldPriceButtonText";
import { useTranslation } from "react-i18next";
import PriceButton from "./PriceButton";
import Box from "@mui/material/Box";
import getPricePeriod from "arena/utils/getPricePeriod";
import StarsIcon from "core/components/StarsIcon";

const PPV = "PPV";
const SUBSCRIPTION = "SUBSCRIPTION";
const DONATION = "DONATION";

export default function ProductPriceButton({ product, price, ...props }) {
  const { t } = useTranslation("arena");

  if (product.viewerPurchase) {
    return <PriceButton text={t("productPriceButton.open")} {...props} />;
  }

  if (!price) {
    return (
      <Button
        disableElevation
        fullWidth
        color="primary"
        size="large"
        sx={{ minHeight: 64 }}
        variant="outlined"
        {...props}
      >
        {t("productPriceButton.simpleOpen")}
      </Button>
    );
  }

  const billingPeriod =
    product.type === SUBSCRIPTION &&
    !price.trialPeriod &&
    getPricePeriod({ t, period: price.period }).toLowerCase();

  let priceValue = <Box>{formatCurrency(price.value, price.currency)}</Box>;

  if (price.currency === "XTR") {
    priceValue = (
      <Box>
        {price.value}
        <StarsIcon sx={{ marginLeft: 0.5, fontSize: 20 }} />
      </Box>
    );
  }

  let value = (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {priceValue}
      {billingPeriod && (
        <Box
          sx={{
            textTransform: "none",
            fontSize: "0.8em",
            opacity: "0.8",
            marginLeft: 0.5,
          }}
        >
          {`/ ${billingPeriod}`}
        </Box>
      )}
    </Box>
  );

  if (product.type === DONATION) {
    return (
      <PriceButton
        text={t("productPriceButton.donate")} // Donate {value}$
        {...props}
      />
    );
  }

  let oldPrice = getOldPriceButtonText(price);

  if (oldPrice && price.currency === "XTR") {
    oldPrice = (
      <Box>
        {price.oldValue}
        <StarsIcon sx={{ marginLeft: 0.5, fontSize: 12 }} />
      </Box>
    );
  }

  if (product.type === SUBSCRIPTION) {
    if (price.trialPeriod) {
      const count = price.trialPeriod;

      if (price.value) {
        return (
          <PriceButton
            currency={price.currency}
            oldPrice={oldPrice}
            price={value}
            text={t("productPriceButton.trialPrice", { count })} // {{count}} days for {value}$ {oldPrice}$
            {...props}
          />
        );
      }

      return (
        <PriceButton
          currency={price.currency}
          oldPrice={oldPrice}
          price={t("productPriceButton.free")}
          {...props}
        />
      );
    }
  }

  if (price.preOrder) {
    return (
      <PriceButton
        currency={price.currency}
        price={value}
        oldPrice={oldPrice}
        text={t("productPriceButton.preOrder")} // PRE-ORDER NOW {value}$ {oldPrice}$
        {...props}
      />
    );
  }

  if (price.nextPrice) {
    return (
      <PriceButton
        currency={price.currency}
        price={value}
        oldPrice={oldPrice}
        text={t("productPriceButton.buyNow")} // Buy now for {value}$
        {...props}
      />
    );
  }

  return (
    <PriceButton
      currency={price.currency}
      price={value}
      oldPrice={oldPrice}
      {...props}
    />
  ); // {value}$
}

ProductPriceButton.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf([SUBSCRIPTION, PPV, DONATION]).isRequired,
    viewerPurchase: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  disabled: PropTypes.bool,
  price: PropTypes.shape({
    oldValue: PropTypes.number,
    value: PropTypes.number.isRequired,
    trialPeriod: PropTypes.number,
    period: PropTypes.number,
    currency: PropTypes.string.isRequired,
    preOrder: PropTypes.bool,
    nextPrice: PropTypes.object,
    minimumBillingCycles: PropTypes.number,
  }).isRequired,
};
