import React from "react";
import { styled } from "@mui/material/styles";
import usePageview from "core/hooks/usePageview";
import useScrollToTop from "core/hooks/useScrollToTop";
import ArenaHelmet from "arena/components/ArenaHelmet";
import { useTranslation } from "react-i18next";
import { useArena } from "core/components/ArenaProvider";
import { useLocation } from "react-router-dom";
import ArenaContinueWatchingContents from "../ArenaContinueWatchingContents";

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 3),
  },
}));

const LIMIT = 12;

export default function History() {
  const { t } = useTranslation("arena");

  const arena = useArena();
  const location = useLocation();

  usePageview(location.pathname, "History");
  useScrollToTop();

  const title = t("continueWatching.title");

  return (
    <Root>
      <ArenaHelmet title={title} />
      <ArenaContinueWatchingContents
        arena={arena}
        view="GRID"
        limit={LIMIT}
        title={title}
        loadMore
      />
    </Root>
  );
}
