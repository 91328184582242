import React from "react";
import PropTypes from "prop-types";
import { useArena } from "core/components/ArenaProvider";
import Stack from "@mui/material/Stack";
import TagList from "arena/components/TagList";
import ContentMetrics from "./ContentMetrics";
import ContentTitle from "./ContentTitle";
import ContentDescription from "./ContentDescription";
import ContentControls from "./ContentControls";
import Box from "@mui/material/Box";

const COLLECTION = "COLLECTION";
const STREAM = "STREAM";
const VIDEO = "VIDEO";

export default function ContentBody({ content }) {
  const arena = useArena();

  if (!content) return null;

  let title = content.title;

  const shareData = {
    title: content.title,
    // text: content.description,
    url: content.shareUrl,
  };

  const showControls =
    arena.isFavoritesEnabled || arena.isLikesEnabled || arena.isShareEnabled;

  const showMetrics =
    arena.displayContentMetadata &&
    (content.type === VIDEO ||
      content.type === STREAM ||
      !!content.ageRestrictions);

  const description = content?.premiumDescription || content?.description;

  return (
    <Box sx={{ pt: { xs: 2, sm: 3 }, pb: { xs: 2, sm: 3 } }} id="content-body">
      <Stack spacing={2}>
        {title && <ContentTitle>{title}</ContentTitle>}
        {showMetrics && <ContentMetrics content={content} />}
        {showControls && (
          <ContentControls
            contentId={content.id}
            isFavoritesEnabled={arena.isFavoritesEnabled}
            isLikesEnabled={arena.isLikesEnabled}
            isShareEnabled={arena.isShareEnabled}
            likes={content.likes}
            shareData={shareData}
          />
        )}
        {content.tags.length > 0 && <TagList tags={content.tags} />}

        {description && <ContentDescription>{description}</ContentDescription>}
      </Stack>
    </Box>
  );
}

ContentBody.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    preview: PropTypes.string,
    publishAt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    premiumDescription: PropTypes.string,
    ageRestrictions: PropTypes.number,
    type: PropTypes.oneOf([VIDEO, STREAM, COLLECTION]),
    // totalViews: PropTypes.number,
    // viewersOnline: PropTypes.number,
    premium: PropTypes.bool,
    viewerPurchase: PropTypes.object,
    likes: PropTypes.object,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
  }),
};
