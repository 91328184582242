import React, { useState } from "react";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import IconButton from "@mui/material/IconButton";
import DialogHeader from "core/components/DialogHeader";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import Fade from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";
import { openLink } from "core/utils/telegram";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const OneClickLoginUrlQuery = loader("./OneClickLoginUrlQuery.graphql");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OpenInWeb() {
  const { t } = useTranslation("arena");

  const [open, setOpen] = useState(false);

  const [getUrl, { loading }] = useLazyQuery(OneClickLoginUrlQuery, {
    fetchPolicy: "network-only",
  });

  const onClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    getUrl()
      .then((data) => {
        onClose();

        return openLink(data.data.oneClickLoginUrl);
      })
      .catch((error) => {
        return console.log(error);
      });
  };

  return (
    <>
      <Dialog
        fullWidth
        disableScrollLock
        TransitionComponent={Transition}
        maxWidth="sm"
        open={open}
        onClose={onClose}
        scroll="body"
      >
        <DialogHeader
          onClose={onClose}
          title={t("connectDeviceDialog.header")}
          position="static"
        />
        <Fade in={loading}>
          <LinearProgress />
        </Fade>

        <DialogContent>
          <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
            <Box>
              <Stack spacing={1}>
                <Typography variant="h6" textAlign={"center"} gutterBottom>
                  {t("openInWebDialog.form.title")}
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  textAlign={"center"}
                >
                  {t("openInWebDialog.form.subtitle")}
                </Typography>
              </Stack>
            </Box>

            <Button
              variant="contained"
              color="primary"
              size="big"
              onClick={onClick}
              fullWidth
              disabled={loading}
            >
              {t("openInWebDialog.form.open")}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              size="big"
              onClick={onClose}
              fullWidth
              disabled={loading}
            >
              {t("openInWebDialog.form.continue")}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      <IconButton onClick={onClick} size="large" disabled={loading}>
        <OpenInBrowserIcon />
      </IconButton>
    </>
  );
}
